<template>
  <div>
    <div class="progress-bar">
      <div class="info">
        <label>{{label}}  <span
       @mouseover="showInfo = true"
          @mouseleave="showInfo = false"
      >
       <span>&#x1F6C8;</span>
      </span></label>
        <label class="percentage">{{percentage}}%</label>
      </div>
      <div class="background-bar"></div>
      <transition appear @before-appear="beforeEnter" @after-appear="enter">
        <div class="tracker-bar"></div>
      </transition>
    </div>

    <transition name="bounce">
      <div class="info-class" v-if="showInfo">
        <p>After Account complete 20%. Qrcode distribute 20%. 25 product add 25% and Minimum topup 100tk 35% </p>
      </div>
    </transition>
</div>
</template>

<script>
export default {
  props: {
    percentage: Number,
    label: String
  },
  data() {
    return {
      showInfo: false
    };
  },
  methods: {
    beforeEnter(el) {
      el.style.width = 0;
    },
    enter(el) {
      el.style.width = `${this.percentage}%`;
      el.style.transition = `width 1s linear`;
    }
  }
};
</script>

<style lang="scss" scoped>
$greydark: #c0cac9;
$black: #333;
$screen: #eff3f4;
$pink: #ff298a;
$grey: #dedfe0;

body {
  background: $screen;
  font-family: "Montserrat", sans-serif;
}

#app {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  height: 100vh;
}
// .container {
//   width: 400px;
//   height: 90px;
//   display: inline-grid;
//   align-items: center;
// }

.active {
  label {
    color: $pink !important;
  }
  .tracker-bar {
    background: $pink !important;
  }
}
.progress-bar {
  width: 100%;
  label {
    color: grey;
  }
  .info {
    font-size: 17px;
    justify-content: space-between;
    display: flex;
    color: grey;
    text-transform: uppercase;
    .percentage {
      font-weight: bolder;
    }
  }
  .background-bar {
    background: #ccc;
    width: 100%;
    height: 20px;
  }

  .tracker-bar {
    background: grey;
    height: 5px;
    width: 0;
    transition: width 0.5s linear;
    margin-top: -5px;
    height: 20px;
    margin-top: -20px;
  }
}
</style>
<style lang="scss" scope>
$purple: #800080;
$nav-bg: #f5f7fa;
$white: #ffffff;
$orange: #ffb715;
$blue: #016ba8;
$muted: #707070;

// .container {
//   margin-top: 50px;
//   padding: 25px;
//   background: $nav-bg;
//   border-radius: 5px;
// }

p {
  color: $muted;
}

.fa-info-circle {
  color: $blue;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    color: $orange;
    transition: 1s all;
  }
}

.info-class {
  background: $blue;
  color: $white !important;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.55);
  width: 30%;
  position: absolute;
  z-index: 500;
  p {
    color: #ffffff;
    font-size: 13px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>







