const GuestModule = () => import('./GuestModule.vue');
// const ShopPage = () => import('./views/ShopPage.vue');




const moduleRoute = {
    path: "/user/service/profile/:id",
    component: GuestModule,

    children:[
        // {
        //     path: "/user/service/profile/:id",
        //     component: ShopPage,
        // },
       
      
    ]
}

export default router => {
    router.addRoute(moduleRoute);
}