const ShopModule = () => import('./ShopModule.vue');
// const ShopPage = () => import('./views/ShopPage.vue');
const ShopPage1 = () => import('./views/ShopPage1.vue');

import auth from '@/middleware/auth.js';
const ProductsPage = () => import('./views/product/product/ProductsPage.vue');
const ProductsAddPage = () => import('./views/product/product/ProductsAddPage.vue');
const ProductsEditPage = () => import('./views/product/product/ProductsEditPage.vue');
const ProductsDublicatePage = () => import('./views/product/product/ProductsDublicatePage.vue');
//Product Management
const ProductManagement = () => import('./views/product/product/ProductManagement.vue');
// service add page
const ServiceItemAddPage = () => import('./views/product/product/ServiceItemAddPage.vue');
const ServiceItemEditPage = () => import('./views/product/product/ServiceItemEditPage.vue');
const ServicePage = () => import('./views/product/product/ServicePage.vue');


const BrandPage = () => import('./views/product/brand/BrandPage.vue');
const BrandAddPage = () => import('./views/product/brand/BrandAddPage.vue');
const BrandEditPage = () => import('./views/product/brand/BrandEditPage.vue');

//Dashboard section

const Dashboard = () => import('./views/dashboard/dashboard.vue');
const ViewItemProductList = () => import('./views/dashboard/ViewItemProductList.vue');
const ViewCartProductList = () => import('./views/dashboard/ViewCartProductList.vue');
const AddToCardProductList = () => import('./views/dashboard/AddToCardProductList.vue');
const BeginCheckoutProductList = () => import('./views/dashboard/BeginCheckoutProductList.vue');
const PurchaseProductList = () => import('./views/dashboard/PurchaseProductList.vue');
const RatingListView = () => import('./views/dashboard/RatingListView.vue');

//banner section

const Banner = () => import('./views/banner/ViewBanner.vue');


//archive product list
const ArchiveProduct = () => import('./views/product/archive/ArchiveProductList.vue');
const UpdateArchiveProduct = () => import('./views/product/archive/UpdateArchiveProduct.vue');

//Priority product list
const PriorityProduct = () => import('./views/product/priority/PriorityProduct.vue');
const PriorityProductAdd = () => import('./views/product/priority/PriorityProductAdd.vue');
//BuyGet product list
const BuyOneGetOne = () => import('./views/product/buyget/BuyOneGetOne.vue');
const BuyOneGetOneAdd = () => import('./views/product/buyget/BuyOneGetOneAdd.vue');
//Offers product list
const OffersProduct = () => import('./views/product/offer/OfferProductPage.vue');
const OffersProductAdd = () => import('./views/product/offer/OfferProductAddPage.vue');
//Package product list
const PackageList = () => import('./views/product/package/PackageList.vue');
// const PackageAdd = () => import('./views/product/package/PackageAdd.vue');
const PackageProductAdd = () => import('./views/product/package/PackageProductAdd.vue');
//BuyGet product list
const VariationList = () => import('./views/product/variations/VariationsList.vue');
const VariationAdd = () => import('./views/product/variations/VariationsAdd.vue');

// order section
const allOrder = () => import('./views/order/AllOrder.vue');
const pendingOrder = () => import('./views/order/PendingOrder.vue');
const processOrder = () => import('./views/order/ProcessOrder.vue');
const completeOrder = () => import('./views/order/CompleteOrder.vue');
const cancleOrder = () => import('./views/order/CancleOrder.vue');
const Invoice = () => import('./views/order/Invoice.vue');
const orderDetails = () => import('./views/order/OrderDetails.vue');
const ZfCourier = () => import('./views/order/ZfCourier.vue');
const RedxCourier = () => import('./views/order/RedxCourier.vue');
const Qrcode = () => import('./views/qrcode/Qrcode.vue');

// Product Details
const ProductDetails = ()=> import('./views/comps/ProductDetailsPage.vue');


//Book Section
const BooksPage = () => import('./views/product/book/BookPage.vue');
const BooksAddPage = () => import('./views/product/book/BookAddPage.vue');
const BooksEditPage = () => import('./views/product/book/BookEditPage.vue');



const moduleRoute = {
    path: "/mypanel/service/profile/:pid",
    component: ShopModule,

    children:[
        // {
        //     path: "/mypanel/service/profile/create",
        //     component: ShopPage,
        // },
        {
            path: "/mypanel/service/profile/:pid",
            component: ShopPage1,
        },

        {
            path: "/mypanel/service/profile/dashboard/:pid",
            component: Dashboard,
        },

        
        {
            path: "/mypanel/service/profile/rating-review/:pid",
            component: RatingListView,
        },



        {
            path: "/mypanel/service/profile/dashboard/:pid/view_item",
            component: ViewItemProductList,
        },
        {
            path: "/mypanel/service/profile/dashboard/:pid/view_cart",
            component: ViewCartProductList,
        },
        {
            path: "/mypanel/service/profile/dashboard/:pid/add_to_card",
            component: AddToCardProductList,
        },
        {
            path: "/mypanel/service/profile/dashboard/:pid/begin_checkout",
            component: BeginCheckoutProductList,
        },
        {
            path: "/mypanel/service/profile/dashboard/:pid/purchase",
            component: PurchaseProductList,
        },




      
        {
            path: "/mypanel/service/profile/:pid/products",
            component: ProductsPage,
            meta:{
                middleware:[auth],
              },
              
        },

        {
            path: "/mypanel/service/profile/:pid/products/add",
            component: ProductsAddPage,
            meta:{
                middleware:[auth],
              },
        },

        {
            path: "/mypanel/service/profile/:pid/products/edit_product/:id",
            component: ProductsEditPage,
            meta:{
                middleware:[auth],
              },
        },

        {
            path: "/mypanel/service/profile/:pid/products/dublicate_product/:id",
            component: ProductsDublicatePage,
            meta:{
                middleware:[auth],
              },
        },

        // add service item sections ServiceItemAddPage

        {
            path: "/mypanel/service/profile/:pid/products/add_service",
            component: ServiceItemAddPage,
            meta:{
                middleware:[auth],
              },
        },
        {
            path: "/mypanel/service/profile/:pid/products/edit_service/:id",
            component: ServiceItemEditPage,
            meta:{
                middleware:[auth],
              },
        },

        {
            path: "/mypanel/service/profile/:pid/service",
            component: ServicePage,
            meta:{
                middleware:[auth],
              },
              
        },

        {
            path: "/mypanel/service/profile/:pid/products/brand",
            component: BrandPage,
            meta:{
                middleware:[auth],
              },
        },
        {
            path: "/mypanel/service/profile/:pid/products/brand-add",
            component: BrandAddPage,
            meta:{
                middleware:[auth],
              },
        },
        {
            path: "/mypanel/service/profile/:pid/products/brand-edit/:id",
            name:'user-brand-edit',
            component: BrandEditPage,
            meta:{
                middleware:[auth],
              },
        },


        //archive product route

        {
          path: "/mypanel/service/profile/:pid/products/archive-products",
          component: ArchiveProduct,
          meta:{
              middleware:[auth],
            },
        },

        {
          path: "/mypanel/service/profile/:pid/products/archive-products-update/:arid",
          component: UpdateArchiveProduct,
          meta:{
              middleware:[auth],
            },
        },


      
        {
            path: "/mypanel/service/profile/:pid/products/priority",
            component: PriorityProduct,
            meta:{
                middleware:[auth],
            },
        },
        {
            path: "/mypanel/service/profile/:pid/products/priority/add",
            component: PriorityProductAdd,
            meta:{
                middleware:[auth],
            },
        },
        {
            path: "/mypanel/service/profile/:pid/products/buyget",
            component: BuyOneGetOne,
            meta:{
                middleware:[auth],
            },
        },
        {
            path: "/mypanel/service/profile/:pid/products/buyget/add",
            component: BuyOneGetOneAdd,
            meta:{
                middleware:[auth],
            },
        },
        {
            path: "/mypanel/service/profile/:pid/products/offer",
            component: OffersProduct,
            meta:{
                middleware:[auth],
            },
        },
        {
            path: "/mypanel/service/profile/:pid/products/offer/add",
            component: OffersProductAdd,
            meta:{
                middleware:[auth],
            },
        },
        {
            path: "/mypanel/service/profile/:pid/products/package",
            component: PackageList,
            meta:{
                middleware:[auth],
            },
        },
        {
            path: "/mypanel/service/profile/:pid/products/package/add",
            component: PackageProductAdd,
            meta:{
                middleware:[auth],
            },
        },
        {
            path: "/mypanel/service/profile/:pid/products/variations",
            component: VariationList,
            meta:{
                middleware:[auth],
            },
        },
        {
            path: "/mypanel/service/profile/:pid/products/variations/add",
            component: VariationAdd,
            meta:{
                middleware:[auth],
            },
        },
        {
          path: "/mypanel/service/profile/:pid/products/banner",
          component: Banner,
          meta:{
              middleware:[auth],
            },
        },


        //order section route

        {
            path:"/mypanel/service/profile/:pid/shop/all-order",
            component: allOrder,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/mypanel/service/profile/:pid/shop/pending-order",
            component: pendingOrder,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/mypanel/service/profile/:pid/shop/process-order",
            component: processOrder,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/mypanel/service/profile/:pid/shop/complete-order",
            component: completeOrder,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/mypanel/service/profile/:pid/shop/cancle-order",
            component: cancleOrder,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/mypanel/service/profile/:pid/shop/order-details/:id",
            component: orderDetails,
            meta:{
                middleware:[auth],
              }
        },

        {
            path:"/mypanel/service/profile/:pid/zf-courier-service",
            component: ZfCourier,
            meta:{
                middleware:[auth],
              }
        },

        {
            path:"/mypanel/service/profile/:pid/redx-courier-service",
            component: RedxCourier,
            meta:{
                middleware:[auth],
              }
        },


        {
            path:"/mypanel/service/profile/:pid/shop/all-invoice/:id",
            component: Invoice,
            meta:{
                middleware:[auth],
              }
        },

        {
            path:"/mypanel/service/profile/:pid/user/shop/qrcode",
            component: Qrcode,
            meta:{
                middleware:[auth],
              }
        },

        {
            path:"/mypanel/service/profile/:pid/product/details/:id",
            component: ProductDetails,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/mypanel/service/profile/:pid/add-book",
            component: BooksAddPage,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/mypanel/service/profile/:pid/view-book",
            component: BooksPage,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/mypanel/service/profile/:pid/edit-book/:id",
            component: BooksEditPage,
            meta:{
                middleware:[auth],
              }
        },
//product management

        {
            path:"/mypanel/service/product-management/:pid",
            component: ProductManagement,
            meta:{
                middleware:[auth],
              }
        },



      
    ]
}

export default router => {
    router.addRoute(moduleRoute);
}