import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const LocalhostModules = () => import('./LocalhostModules.vue');
const LocalhostAddPage = () => import('./page/LocalhostAddPage.vue');
const LocalhostPage = () => import('./page/LocalhostPage.vue');
const LocalhostEditPage = () => import('./page/LocalhostEditPage.vue');

const moduleRoute = {
    path: "/admin/localhost-section",
    component: LocalhostModules,
    meta:{
        middleware:[auth, admin],
      },
  
    children:[
        {
            path: "/admin/localhost-section",
            component: LocalhostPage,
            meta:{
                middleware:[auth, admin],
              },
        },
        {
          path: "/admin/localhost-section/add-localhost",
          component: LocalhostAddPage,
          meta:{
              middleware:[auth, admin],
            },
      },
      {
        path: "/admin/localhost-section/edit-localhost/:id",
        component: LocalhostEditPage,
        meta:{
            middleware:[auth, admin],
          },
    },
    ]
};
export default router => {
    router.addRoute(moduleRoute);
  };
  