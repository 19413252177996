// import router from '@/router';
import auth from '@/middleware/auth.js';
const LocalhostModules = () => import('./LocalhostModules.vue');
const HomePage = () => import('./page/HomePage.vue');
const Transictions = () => import('./page/transactions/Transictions.vue');
const Employee = () => import('./page/employee/ViewEmployee.vue');
const serviceProfile = () => import('./page/serviceProfile/ServiceProfile.vue');



//employee section end
const moduleRoute = {
    path: "/localhost-mypanel",
    component: LocalhostModules,
    meta:{
        middleware:[auth],
      },
  
    children:[
       {
        path: "/localhost-mypanel",
        component: HomePage,
        meta:{
            middleware:[auth],
          }
       },

       {
        path: "/localhost-mypanel/transactions",
        component: Transictions,
        meta:{
            middleware:[auth],
          }
       },
       {
        path: "/localhost-mypanel/employee",
        component: Employee,
        meta:{
            middleware:[auth],
          }
       },
       {
        path: "/localhost-mypanel/service-profile",
        component: serviceProfile,
        meta:{
            middleware:[auth],
          }
       },

       //user employee work history start

    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  