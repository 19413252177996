// import router from '@/router';
import auth from '@/middleware/auth.js';
import inventoryAuth from '@/middleware/inventory_auth.js';
const InventoryModules = () => import('./InventoryModules.vue');
const HomePage = () => import('./page/HomePage.vue');
//stock section
const StockAdd = ()=> import('./page/stock/StockAdd.vue');
const StockView = ()=> import('./page/stock/StockView.vue');
const StockEdit = ()=> import('./page/stock/StockEdit.vue');

//Item section
const ItemSection = ()=> import('./page/ItemSection/ItemSection.vue');
const ItemAdd = ()=> import('./page/ItemSection/ItemAdd.vue');
const ItemAddMultiple = ()=> import('./page/ItemSection/ItemAddMultiple.vue');
const ItemView = ()=> import('./page/ItemSection/ItemView.vue');
const ItemEdit = ()=> import('./page/ItemSection/ItemEdit.vue');
const ArchiveProduct = ()=> import('./page/ItemSection/ArchiveProduct.vue');

//inventory section
const InventorySection = () => import('./page/inventory/InventorySection.vue');
const InventorySectionEdit = () => import('./page/inventory/InventorySectionEdit.vue');

//inventory sell section
const SellSection = () => import('./page/sell_section/SellSection.vue');

// inventory order section
const OrderSection = () => import('./page/order_section/OrderList.vue');
const CancelSection = () => import('./page/order_section/CancelList.vue');

// inventory Invoice section
const Invoice = () => import('./page/invoice/Invoice.vue');
const PurchaseInvoice = () => import('./page/invoice/PurchaseInvoice.vue');

// Statemate Cost
const Statemate = () => import('./page/stamate/Stamate.vue');

//inventory report section
const Report = () => import('./page/report/Report.vue');
const AllSellReport = () => import('./page/report/AllSellReport.vue');
const DailySellReport = () => import('./page/report/DaillySellReport.vue');
const WeeklySellReport = () => import('./page/report/WeeklySellReport.vue');
const CurrentMonthSellReport = () => import('./page/report/CurrentMonthSellReport.vue');
const LastMonthSellReport = () => import('./page/report/LastMonthSellReport.vue');
const LastThirtyDaysSellReport = () => import('./page/report/LastThirtyDaysSellReport.vue');

const CustomSellReport = () => import('./page/report/CustomSellReport.vue');

//account sheet report
// const DailySheet = ()=> import('./page/accountsheet/DailySheet.vue');
const MonthlySheet = ()=> import('./page/accountsheet/MonthlySheet.vue');
const DailyOrder = ()=> import('./page/accountsheet/DailyOrder.vue');

// waste section
const WastSection = () => import('./page/waste/WasteSection.vue');
//wasted record section
const DailyWasteRecord = () =>import('./page/waste/Report/DailyWasteReport.vue');
const WeeklyWasteRecord = () =>import('./page/waste/Report/WeeklyWasteReport.vue');
const MontlyWasteReport = () =>import('./page/waste/Report/MontlyWasteReport.vue');
const LastMontlyWasteReport = () =>import('./page/waste/Report/LastMontlyWasteReport.vue');
const LastThirtyDaysWasteReport = () =>import('./page/waste/Report/LastThirtyDaysWasteReport.vue');
const AllWasteReport = () =>import('./page/waste/Report/AllWasteReport.vue');

//utility coast
const AddUtilityCoast = () => import('./page/utility/AddUtilityCoast.vue');
const ViewUtilityCoast = () => import('./page/utility/ViewUtilityCoast.vue');
const EditUtilityCoast = () => import('./page/utility/EditUtilityCoast.vue');

//other income
const AddOtherIncome = () => import('./page/othersIncome/AddOtherIncome.vue');
const EditOtherIncome = () => import('./page/othersIncome/EditOtherIncome.vue');
const ViewOtherIncome = () => import('./page/othersIncome/ViewOtherIncome.vue');









//purchase section
const PurChaseSection = () => import('./page/purchase_section/PurchaseSection.vue');
const PurchaseSectionDetails = () => import('./page/purchase_section/PurchaseSectionDetails.vue');

//product alert quantity
const ProductAlertQuantity = () => import('./page/alert_quantity/ProductAlertQuantity.vue');

// due order list
const DueList = () => import('./page/order_section/DueList.vue');
const CustomDueList = () => import('./page/order_section/CustomDueList.vue');
const DueDetailsList = () => import('./page/order_section/DueDetailsList.vue');
const TodayDueList = () => import('./page/order_section/TodayDueList.vue');
const WeeklyDueList = () => import('./page/order_section/WeeklyDueList.vue');
const MonthlyDueList = () => import('./page/order_section/MonthlyDueList.vue');
const LastMonthlyDueList = () => import('./page/order_section/LastMonthlyDueList.vue');
const LastThirtydaysDueList = () => import('./page/order_section/LastThirtyDaysDueList.vue');

// customer list
const CustomerList = () => import('./page/customer_list/CustomerList.vue');
const CustomerLegerd = () => import('./page/legerd/CustomerLegerd.vue');

const CustomerLegerdDue = () => import('./page/legerd/CustomerLegerdDue.vue');

// supplier section
const SupplierList = () => import('./page/customer_list/SupplierList.vue');
const SupplierLegerdDue = () => import('./page/legerd/SupplierLegerdDue.vue');
const SupplierLegerd = () => import('./page/legerd/SupplierLegerd.vue');

//report purchase section 
const AllPurchaseReport = () => import('./page/report/purchase/AllPurchaseReport.vue');
const CustomPurchaseReport = () => import('./page/report/purchase/CustomPurchaseReport.vue');
const CurrentMonthePuchaseReport = () => import('./page/report/purchase/CurrentMonthPurchasesReport.vue');
const DailyPurchaseReport = () => import('./page/report/purchase/DailyPurchaseReport.vue');
const WeeklyPurchaseReport = () => import('./page/report/purchase/WeeklyPurchaseReport.vue');
const LastMonthPurchaseReport = () => import('./page/report/purchase/LastMonthPurchaseReport.vue');
const LastThirtyDaysPurchaseReport = () => import('./page/report/purchase/LastThirtyDaysPurchaseReport.vue');

// return section
const ReturnSection = () => import('./page/return/return.vue');
const PurchaseReturnSection = () => import('./page/return/purchaseReturn.vue');

const CustomerReturn = () => import('./page/legerd/CustomerReturn.vue');
const SupplierReturn = () => import('./page/legerd/SupplierReturn.vue');



//employee section end
const moduleRoute = {
    path: "/inventory-farmacy/:spid",
    component: InventoryModules,
    meta:{
        middleware:[auth, inventoryAuth],
      },
  
    children:[
       {
        path: "/inventory-farmacy/:spid",
        component: HomePage,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/stock-add",
        component: StockAdd,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       {
        path: "/inventory-farmacy/:spid/stock-view",
        component: StockView,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/stock-edit/:id",
        component: StockEdit,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/item-section",
        component: ItemSection,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/add-item-section",
        component: ItemAdd,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       {
        path: "/inventory-farmacy/:spid/add-item-archive-section",
        component: ArchiveProduct,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/add-multiple-item-section",
        component: ItemAddMultiple,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/view-item-section",
        component: ItemView,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/edit-item-section/:id",
        component: ItemEdit,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/inventory-section",
        component: InventorySection,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/inventory-section-edit/:id",
        component: InventorySectionEdit,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

      //  {
      //   path: "/inventory-farmacy/:spid/inventory-section-edit/:id",
      //   component: InventorySectionEdit,
      //   meta:{
      //       middleware:[auth, inventoryAuth],
      //     }
      //  },
       {
        path: "/inventory-farmacy/:spid/inventory-sell-section",
        component: SellSection,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },


       {
        path: "/inventory-farmacy/:spid/inventory-order-section",
        component: OrderSection,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       {
        path: "/inventory-farmacy/:spid/inventory-order_cancel-section",
        component: CancelSection,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/inventory-invoice-section/:id",
        component: Invoice,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/inventory-purchase-invoice-section/:id",
        component: PurchaseInvoice,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },



       {
        path: "/inventory-farmacy/:spid/inventory-report-section",
        component: Report,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/all-sell-report",
        component: AllSellReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/custom-sell-report",
        component: CustomSellReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },


      //  {
      //   path: "/inventory-farmacy/:spid/all-sell-report",
      //   component: AllSellReport,
      //   meta:{
      //       middleware:[auth, inventoryAuth],
      //     }
      //  },

       {
        path: "/inventory-farmacy/:spid/daily-sell-report",
        component: DailySellReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       {
        path: "/inventory-farmacy/:spid/weekly-sell-report",
        component: WeeklySellReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       {
        path: "/inventory-farmacy/:spid/current-month-sell-report",
        component: CurrentMonthSellReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       {
        path: "/inventory-farmacy/:spid/last-month-sell-report",
        component: LastMonthSellReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/last-thirty-days-sell-report",
        component: LastThirtyDaysSellReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/get_statemate_cost/:id",
        component: Statemate,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       {
        path: "/inventory-farmacy/:spid/daily_sheet_report",
        component: DailyOrder,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },


       {
        path: "/inventory-farmacy/:spid/monthly_sheet_report",
        component: MonthlySheet,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       // waste section
       {
        path: "/inventory-farmacy/:spid/waste_section",
        component: WastSection,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       
       {
        path: "/inventory-farmacy/:spid/waste_section_daily_record",
        component: DailyWasteRecord,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/waste_section_weekly_record",
        component: WeeklyWasteRecord,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       {
        path: "/inventory-farmacy/:spid/waste_section_monthly_record",
        component: MontlyWasteReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       {
        path: "/inventory-farmacy/:spid/waste_section_monthly_last_record",
        component: LastMontlyWasteReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       
       {
        path: "/inventory-farmacy/:spid/waste_section_last_thirty_days_record",
        component: LastThirtyDaysWasteReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/waste_section_all_record",
        component: AllWasteReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       //utility coast section

       {
        path: "/inventory-farmacy/:spid/add_utility_coast",
        component: AddUtilityCoast,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/view_utility_coast",
        component: ViewUtilityCoast,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/utility-coast-edit/:id",
        component: EditUtilityCoast,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/add_others_income",
        component: AddOtherIncome,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/view_others_income",
        component: ViewOtherIncome,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/others-income-edit/:id",
        component: EditOtherIncome,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },











       {
        path: "/inventory-farmacy/:spid/purchase-section",
        component: PurChaseSection,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/purchase-details-history",
        component: PurchaseSectionDetails,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },


       {
        path: "/inventory-farmacy/:spid/product-alert-quantity-history",
        component: ProductAlertQuantity,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       
       {
        path: "/inventory-farmacy/:spid/due-order-section",
        component: DueList,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/custom-due-order-section",
        component: CustomDueList,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       
       {
        path: "/inventory-farmacy/:spid/today-due-order-section",
        component: TodayDueList,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/weekly-due-order-section",
        component: WeeklyDueList,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/monthly-due-order-section",
        component: MonthlyDueList,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/last-monthly-due-order-section",
        component: LastMonthlyDueList,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       {
        path: "/inventory-farmacy/:spid/last-thirty-due-order-section",
        component: LastThirtydaysDueList,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/inventory-order-due-details-section/:uid",
        component: DueDetailsList,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },



       {
        path: "/inventory-farmacy/:spid/inventory-order-customer-list",
        component: CustomerList,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/customer-list-legerd/:uid",
        component: CustomerLegerd,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/customer-list-legerddue/:uid",
        component: CustomerLegerdDue,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },



       {
        path: "/inventory-farmacy/:spid/supplier-list",
        component: SupplierList,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/supplier-list-ledgerddue/:uid",
        component: SupplierLegerdDue,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/supplier-list-ledgerd/:uid",
        component: SupplierLegerd,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

     
       {
        path: "/inventory-farmacy/:spid/daily-purchase-report",
        component: DailyPurchaseReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/weekly-purchase-report",
        component: WeeklyPurchaseReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

   
       {
        path: "/inventory-farmacy/:spid/last-month-purchase-report",
        component: LastMonthPurchaseReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       {
        path: "/inventory-farmacy/:spid/last-thiry-days-purchase-report",
        component: LastThirtyDaysPurchaseReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/current-month-purchase-report",
        component: CurrentMonthePuchaseReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       {
        path: "/inventory-farmacy/:spid/all-purchase-report",
        component: AllPurchaseReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       

       {
        path: "/inventory-farmacy/:spid/custom-purchase-report",
        component: CustomPurchaseReport,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },



       // return section
       {
        path: "/inventory-farmacy/:spid/return-section",
        component: ReturnSection,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },


       {
        path: "/inventory-farmacy/:spid/customer-return-section/:uid",
        component: CustomerReturn,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },

       //purchase return section
       {
        path: "/inventory-farmacy/:spid/purchase-return-section",
        component: PurchaseReturnSection,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },
       

       {
        path: "/inventory-farmacy/:spid/supplier-return-section/:uid",
        component: SupplierReturn,
        meta:{
            middleware:[auth, inventoryAuth],
          }
       },




    ]
};

export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  