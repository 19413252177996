<template>
  <div>
    <label>
      <input type="checkbox" v-model="isChecked"> {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    label: String
  },
  computed: {
    isChecked: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue); // Emit input event to update the parent component
      }
    }
  }
};
</script>