// import axios from "axios";
import { createWebHistory, createRouter } from "vue-router";
// const HomePage = () => import('@/components/main/HomePage.vue');
const HomePage1 = () => import('@/components/main/HomePageNew.vue');
const LoginPage = () => import('@/components/main/LoginPage.vue');
const ServicePage = () => import('@/components/main/ServicePage.vue');
// const ShopPage = () => import('@/components/main/ShopPage.vue');
// const ShopPage = () => import('@/modules/new-shop/views/shop/AllShop.vue');

// new shopping mall and sme enterprenure
const AllShoppingMallHomePageLink = () => import('@/components/main/AllShopingMallHomePageLink.vue');
const SmeEnterprenure = () => import('@/components/main/SmeEnterprenure.vue');

const MyShopAllViewNew = () => import('@/components/main/MyShopAllViewNew.vue');
const AllKeyProductViewNew = () => import('@/components/main/AllKeyProductViewNew.vue');
const AllOfferViewNew = () => import('@/components/main/AllOfferViewNew.vue');
const AllPackageViewNew = () => import('@/components/main/AllPackageViewNew.vue');
const MyProfilePage = () => import('@/components/main/MyProfilePage.vue');
const SoftMarket = () => import('@/components/main/SoftMarket.vue');
const Test = () => import('@/components/main/Test.vue');
const Test3 = () => import('@/components/main/Test3.vue');
const TestEvent = () => import('@/components/main/TestEvent.vue');
const Test4 = () => import('@/components/main/Test4.vue');
const ConnectionFriends = () => import('@/components/main/ConnectingFriends.vue');
const MyOrders = () => import('@/components/main/MyOrders.vue');
const CategoryShop = () => import('@/components/main/CategoryShop.vue');

const CommunityShop = () => import('@/components/main/ComunitySinglePage.vue');
const CommunityAmbassador = () => import('@/components/main/apply/CommunityAmbasidor.vue');
const Reseller = () => import('@/components/main/apply/Reseller.vue');
const Youtuber = () => import('@/components/main/apply/Youtuber.vue');
const GraphicDesigner = () => import('@/components/main/apply/GraphicDesigner.vue');
const Photographer = () => import('@/components/main/apply/Photographer.vue');

const PrivacyPolicy = () => import('@/components/main/PrivacyPolicy.vue');
const FaqPage = () => import('@/components/main/FaqPage.vue');
const TermsConditionsPage = () => import('@/components/main/TermsConditionsPage.vue');
const DisclaimerPage = () => import('@/components/main/DisclaimerPage.vue');
const ReturnPolicyPage = () => import('@/components/main/ReturnPolicyPage.vue');
const AboutUsPage = () => import('@/components/main/AboutUsPage.vue');
// const ProfilePage = () => import('@/components/main/ProfilePage.vue');
const CommunityPage = () => import('@/components/main/CommunityPage.vue');
const FairPage = () => import('@/components/main/FairPage.vue');
const FairShop = () => import('@/components/main/FairSinglePage.vue');
import User from '../middleware/User'; 

// New 
const MyService = () =>  import('@/components/main/comps/MyService.vue');
const WorkstationDataNew = () =>  import('@/components/main/WorkstationDataNew.vue');
const WsCategoryDataNew = () =>  import('@/components/main/WsCategoryDataNew.vue');
const AllCommunityShopsNew = () =>  import('@/components/main/AllCommunityShopsNew.vue');
const ExploreItemsNew = () =>  import('@/components/main/ExploreItemsNew.vue');
const OthersAllExclusiveNew = () =>  import('@/components/main/OthersAllExclusiveNew.vue');
const AllServicesNew = () => import('@/components/main/AllServicesNew.vue');


//forgot password
const ForgotPassword = () => import('@/modules/user/view/auth_section/ForgotPassword.vue');

//template section
// const InventorySuccess = () => import('@/components/template/InventorySuccess.vue');
// const Error = () => import('@/components/template/Error.vue');

// import auth from '@/middleware/auth.js';
// import admin from '@/middleware/admin';
// import GeneralRoutes from "./router.js";
// import PropertyRoutes from "./property.js";

// var allRoutes = [];
// // allRoutes = allRoutes.concat(GeneralRoutes, PropertyRoutes);
// allRoutes = allRoutes.concat(GeneralRoutes);

// const routes = allRoutes;

const BkashMessage = () => import('@/components/BkashMessage.vue')


const requireNoAuth = (to, from, next) => {
  let user = User.logedIn();
  if (user) {
    next({ name: "Home-page" });
  }
  next();
};

const routes = [
  {
    path: "/",
    name: "Home-page",
    component: HomePage1
  },
  {
    path: "/home",
    name: "Home-page-1",
    component: HomePage1
  },
  {
    path: "/login",
    name: "Login-page",
    component: LoginPage,
    beforeEnter: requireNoAuth,
  },
  {
    path: "/service",
    name: "Service-page",
    component: ServicePage,
  },
  // {
  //   path: "/all-shop",
  //   name: "Shop-page",
  //   component: ShopPage,
  // },
  {
    path: "/my-all-shop",
    name: "my-shop-all",
    component: MyShopAllViewNew,
  },
  {
    path: "/others-exclusive",
    name: "others-exclusive",
    component: OthersAllExclusiveNew,
  },
  {
    path: "/all-offers",
    name: "all-offers",
    component: AllOfferViewNew,
  },

  {
    path: "/all-key-products",
    name: "all-key-products",
    component: AllKeyProductViewNew,
  },

  {
    path: "/all-packages",
    name: "all-packages",
    component: AllPackageViewNew,
  },
  {
    path: "/profile",
    name: "my-profile-page",
    component: MyProfilePage,
  },
  {
    path: "/all-services",
    name: "all-services",
    component: AllServicesNew,
  },
  {
    path: "/soft-market",
    name: "Soft-market",
    component: SoftMarket,
  },
  {
    path: "/connecting_friends",
    name: "connection-friends",
    component: ConnectionFriends,
  },

  {
    path: "/test",
    name: "test",
    component: Test,
  },
  {
    path: "/test-3",
    name: "test3",
    component: Test3,
  },
  
  {
    path: "/test-event",
    name: "test-event",
    component: TestEvent,
  },

  {
    path: "/test-4",
    name: "test4",
    component: Test4,
  },


  {
    path: "/softmarket/category-shop/:catId",
    name: "Category Shop",
    component: CategoryShop,
  },

  {
    path: "/community-shop/:gid",
    name: "community-shop",
    component: CommunityShop,
  },
  {
    path: "/fair-shop/:gid",
    name: "fair-shop",
    component: FairShop,
  },



  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/faq",
    name: "Faq Page",
    component: FaqPage,
  },
  {
    path: "/terms-and-condition",
    name: "Terms And Condition Page",
    component: TermsConditionsPage,
  },
  {
    path: "/disclaimer",
    name: "Disclaimer Page",
    component: DisclaimerPage,
  },
  {
    path: "/return-policy",
    name: "Return Policy Page",
    component: ReturnPolicyPage,
  },
  {
    path: "/about-us",
    name: "About Us Page",
    component: AboutUsPage,
    meta: async (route) => {
     
      alert(route)
  },
  },
  // {
  //   path: "/profile",
  //   name: "Profile Page",
  //   component: ProfilePage,
  // },
  {
    path: "/community",
    name: "CommunityPage",
    component: CommunityPage,
  },
  {
    path: "/fair",
    name: "Fairage",
    component: FairPage,
  },
  {
    path: "/service-view",
    name: "MyService",
    component: MyService,
  },
  {
    path: "/my-orders",
    name: "MyOrders",
    component: MyOrders,
  },
// New
  {
   path: "/user/forgot-password",
   component: ForgotPassword
  },
  {
   path: "/user/apply-community-ambassador",
   component: CommunityAmbassador
  },

  {
   path: "/user/apply-reseller",
   component: Reseller
  },

  {
   path: "/user/apply-youtuber",
   component: Youtuber
  },

  {
   path: "/user/apply-graphic-designer",
   component: GraphicDesigner
  },

  {
   path: "/user/apply-photographer",
   component: Photographer
  },

  {
    path: "/category/:gid",
    component: WorkstationDataNew
  },
  {
    path: "/category/product/:gid/:cid",
    component: WsCategoryDataNew
  },
  {
    path: "/community-shops/:gid",
    name: "community-shops",
    component: AllCommunityShopsNew,
  },
  {
    path: "/explore",
    name: "explore",
    component: ExploreItemsNew,
  },

  {
    path: "/bkash-message",
    name: "/bkashmessage",
    component: BkashMessage,
  },

  {
    path: "/all-shoping-mall",
    name: "allshoppingmallhomepagelink",
    component: AllShoppingMallHomePageLink,
  },
  {
    path: "/sme-enterprenure",
    name: "smeenterprenure",
    component: SmeEnterprenure,
  },


  // {
  //   path: "/inventory-success",
  //   name: "inventorysuccess",
  //   component: InventorySuccess,
  // },
  // {
  //   path: "/error-template",
  //   name: "error-template",
  //   component: Error,
  // },

];



// const needsAuth = false;

const router = createRouter({
  history: createWebHistory(),
  routes,
});


function nextFactory(context, middleware, index){
  const subsquentMiddleware = middleware[index];
  if(!subsquentMiddleware){
    return context.next;
  }

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index+1);
    subsquentMiddleware({...context, next:nextMiddleware});
  };
}



router.beforeEach( async (to, from, next) => {

  var nowTime = new Date().getTime();
  if( localStorage.getItem('expaire') != ''){
    if( nowTime >= localStorage.getItem('expaire') * 1000){
      User.logOut();
      // next({ name: "Login-page" });
    }
  }
 

  if(to.meta.middleware){
    const middleware = Array.isArray(to.meta.middleware)? to.meta.middleware : [to.meta.middleware];
    const context = {from , next, router, to};
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({...context, next:nextMiddleware});
  }

  // if(to.meta.product == 'details'){
  //   const response = await axios.post("/api/user/products/product/"+to.params.id);

  //   document.title = response.data.product_name +"-"+ response.data.shopinfo?.name;
  //   const metaDescription = "Soft Commerce online platform offers a far-stretching range of services in all categories including Health, Food Education, Transport, Property, Consultancy, Information & Technology, Fashion, Beauty, Tourism, and many more services. In one word, this platform offers you every kind of service, which you can just imagine! At Soft Commerce, we provide everything you need under one sunshade";
  //   const metaImage = response.data.feature_image;
  //   // Update or create meta tags
  //   document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
  //   const url = "https://scommercebd.com/user/service/shop/items/"+to.params.pid+"/"+to.params.id;

  //   document.querySelector('meta[name="url"]').setAttribute('content', url);

  //   let metaImageTag = document.querySelector('meta[property="og:image"]');
  //   if (metaImageTag) {
  //     metaImageTag.setAttribute('content', metaImage);
  //   } else {
  //     metaImageTag = document.createElement('meta');
  //     metaImageTag.setAttribute('property', 'og:image');
  //     metaImageTag.setAttribute('content', metaImage);
  //     document.head.appendChild(metaImageTag);
  //   }
  //   next();
  // }

  return next();
});

export default router;   