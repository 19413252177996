import axios from "axios";
export default function inventoryAuth({ to, next, router}){

        axios.get('/api/user_inventory_subscription/'+to.params.spid)
        .then(res => {
            if(res.data.type == 'success'){
               
                return next();
            }else{
               router.push('/user/service/shop/'+to.params.spid);
            }
        })
        .catch(error => console.log(error.response.data));

    
}

