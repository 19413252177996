<template>
  <div>test</div>
  <!-- <div> -->
    <!-- <span>{{distance}}</span> -->
    <!-- <div class="map" ref="oracalse" style="display:none"></div> -->
  <!-- </div> -->
</template>



<script>


// import { ref, onMounted } from "vue";
// export default {
//   props: ["lat", "lng", "destination", "address", "location"],
//   setup(props, context) {
//     const oracalse = ref("");
//     const address = ref("");
//     const destination = ref("");
//     const lat = ref("");
//     const lng = ref("");
//     const lat1 = ref("");
//     const lng1 = ref("");
//     const distance = ref(0);

//     onMounted(() => {
//       //  var coords = new google.maps.LatLng(23.810332, 90.4125181);
//       // const map = new google.maps.Map(oracalse.value, {
//       //     center:coords,
//       //     zoom: 15,
//       //     mapTypeId: google.maps.MapTypeId.ROADMAP
//       // });

//       //   const directionsService = new google.maps.DirectionsService();
//       //   const directionsRenderer = new google.maps.DirectionsRenderer();

//       setTimeout(() => {
//         var start_lat = props.lat.toString();
//         var start_lon = props.lng.toString();
//         var end_lat = props.destination[1].toString();
//         var end_lon = props.destination[0].toString();
//         // console.log(end_lat);
//         // console.log(end_lon);
//         // console.log(start_lat);
//         // console.log(start_lon);

//         // console.log(props.address);
//         // console.log(props.location);

//         var origin1 = new google.maps.LatLng(start_lat, start_lon);
//         var origin2 = props.address;
//         var destinationA = props.location;
//         var destinationB = new google.maps.LatLng(end_lat, end_lon);

//         var request = {
//           origins: [origin1, origin2],
//           destinations: [destinationA, destinationB],
//           travelMode: google.maps.TravelMode.DRIVING
//         };

//         var service = new google.maps.DistanceMatrixService();

//         service.getDistanceMatrix(request, (response, status) => {
//         //   console.log(response.rows[1].elements[0].distance.text);
//           distance.value = response?.rows[1]?.elements[0]?.distance?.text;
//         });
//       }, 500);
//     });

//     return {
//       oracalse,
//       lat,
//       lng,
//       lat1,
//       lng1,
//       destination,
//       distance
//     };
//   },

//   mounted() {
//     //    const geocoder = new Geocoder(this, Locale.getDefault());
//     //         console.log( geocoder.getFromLocation(45.4215296, -75.6971931, 1));
//   }
// };







</script>


