let AppStorage = class AppStorage {
   storeToken(token) {
      localStorage.setItem('token', token);
   }

   storeUser(user) {
      localStorage.setItem('user', user);
   }

   storeMobile(mobile) {
      localStorage.setItem('phone', mobile);
   }

   storeExpaire(expaire) {
      localStorage.setItem('expaire', expaire);
   }

   storeAddress(address) {
      localStorage.setItem('address', address);
   }

   storeRole(role) {
      if (role) {
         localStorage.setItem('role_name', role);
      }
   }

   storeRolev(rolev) {
      if (rolev) {
         localStorage.setItem('role_value', rolev);
      }
   }


   storeUID(uid) {
      if (uid) {
         localStorage.setItem('role_uid', uid);
      }
   }

   storeEmployee(employee) {
      if (employee) {
         localStorage.setItem('is_employee', employee);
      }
   }
   storeLocalhost(localhost) {
      if (localhost) {
         localStorage.setItem('localhost', localhost);
      }
   }
   storeAmbasidor(ambasidor) {
      if (ambasidor) {
         localStorage.setItem('ambasidor', ambasidor);
      }
   }
   storeReseller(reseller) {
      if (reseller) {
         localStorage.setItem('reseller', reseller);
      }
   }




   store(user, token, mobile, expaire, role, rolev, uid, address, employee, localhost, ambasidor, reseller) {
      this.storeToken(token);
      this.storeUser(user);
      this.storeMobile(mobile);
      this.storeExpaire(expaire);
      this.storeRole(role);
      this.storeRolev(rolev);
      this.storeUID(uid);
      this.storeAddress(address);
      this.storeEmployee(employee);
      this.storeLocalhost(localhost);
      this.storeAmbasidor(ambasidor);
      this.storeReseller(reseller);
   }

   clear() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('phone');
      localStorage.removeItem('expaire');
      localStorage.removeItem('address');
      localStorage.removeItem('role_name');
      localStorage.removeItem('role_value');
      localStorage.removeItem('role_uid');
      localStorage.removeItem('is_employee');
      localStorage.removeItem('localhost');
      localStorage.removeItem('ambasidor');
      localStorage.removeItem('reseller');

   }

   getToken() {
      return localStorage.getItem('token');

   }

   getUser() {
      return localStorage.getItem('user');
   }

   getPhone() {
      return localStorage.getItem('phone');
   }

   getAdmin() {
      return localStorage.getItem('role_name');
   }


   getAdminType() {
      return localStorage.getItem('role_value');
   }

   
   getLocalhost() {
      return localStorage.getItem('localhost') ? true : false;
   }


   getAmbasidor() {
      return localStorage.getItem('ambasidor') ? true : false;
   }
   getReseller() {
      return localStorage.getItem('reseller') ? true : false;
   }



   getUid() {
      return localStorage.getItem('role_uid');
   }
   getEmployee() {
      return localStorage.getItem('is_employee') ? localStorage.getItem('is_employee') : false;
   }
   getChatId(){
      return localStorage.getItem('chat_user_id') ?  localStorage.getItem('chat_user_id') : '';
   }

}

export default AppStorage = new AppStorage();