import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const EmployeeModules = () => import('./EmployeeModules.vue');
const EmployeePage = () => import('./page/EmployeePage.vue');
const EmployeeAddPage = () => import('./page/EmployeeAddPage.vue');
const EmployeeEditPage = () => import('./page/EmployeeEditPage.vue');

const moduleRoute = {
    path: "/admin/employee-section",
    component: EmployeeModules,
    meta:{
        middleware:[auth, admin],
      },
  
    children:[
        {
            path: "/admin/employee-section",
            component: EmployeePage,
            meta:{
                middleware:[auth, admin],
              },
        },
        {
          path: "/admin/employee-section/add-employee",
          component: EmployeeAddPage,
          meta:{
              middleware:[auth, admin],
            },
      },
      {
        path: "/admin/employee-section/edit-employee/:id",
        component: EmployeeEditPage,
        meta:{
            middleware:[auth, admin],
          },
    },
    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  