import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const CategoriesModules = () => import('./CategoriesModules.vue');
const CategoryPage = () => import('./page/CategoryPage.vue');
const CategoryAddPage = () => import('./page/CategoryAddPage.vue');
const CategoryEditPage = () => import('./page/CategoryEditPage.vue');

const GroupSKUAddPage = () => import('./page/sku/GroupSKUAddPage.vue');
const GroupSKUEditPage = () => import('./page/sku/GroupSKUEditPage.vue');
const GroupSKUPage = () => import('./page/sku/GroupSKUPage.vue');

// sku section
const SkuAddPage = () => import('./page/sku/SkuAddPage.vue');
const SkuEditPage = () => import('./page/sku/SkuEditPage.vue');
const SkuPage = () => import('./page/sku/SkuPage.vue');
// sku section
const DownloadProductExcel = () => import('./page/sku/DownloadProductExcel.vue');
const ShopCatelogExcel = () => import('./page/sku/ShopCatelogExcel.vue');
const OfferProductCatelogExcel = () => import('./page/sku/OfferProductCatelogExcel.vue');
const ServiceCatelogExcel = () => import('./page/sku/ServiceCatelogExcel.vue');
// all ads analytics
const AllAdsAnalytics = () => import('./page/ads/AllAdsAnalytics.vue')
const AddToCartProductList = () => import('./page/ads/AddToCartProductList.vue');
const ViewCartProductList = () => import('./page/ads/ViewCartProductList.vue');
const BeginCheckoutProductList = () => import('./page/ads/BeginCheckoutProductList.vue');
const PurchaseProductList = () => import('./page/ads/PurchaseProductList.vue');
const ViewItemProductList = () => import('./page/ads/ViewItemProductList.vue');


const moduleRoute = {
  path: "/admin/category",
  component: CategoriesModules,
  meta: {
    middleware: [auth, admin],
  },

  children: [
    {
      path: "/admin/category",
      component: CategoryPage,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/category-add",
      component: CategoryAddPage,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/category-edit/:id",
      component: CategoryEditPage,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/group-sku/add-group-sku",
      component: GroupSKUAddPage,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/group-sku/edit-group-sku/:id",
      component: GroupSKUEditPage,
      meta: {
        middleware: [auth, admin],
      },
    },
    
    {
      path: "/admin/group-sku/view-group-sku",
      component: GroupSKUPage,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/sku-section/add-sku",
      component: SkuAddPage,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/sku-section/edit-sku/:id",
      component: SkuEditPage,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/sku-section/view-sku",
      component: SkuPage,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/sku-section/download-product-excel",
      component: DownloadProductExcel,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/shop-section/download-product-excel",
      component: ShopCatelogExcel,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/offer-section/download-product-excel",
      component: OfferProductCatelogExcel,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/service-section/download-product-excel",
      component: ServiceCatelogExcel,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/ads-section/all-ads-analytics",
      component: AllAdsAnalytics,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/ads-section/all-ads-add-to-cart",
      component: AddToCartProductList,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/ads-section/all-ads-view-item",
      component: ViewItemProductList,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/ads-section/all-ads-view-cart",
      component: ViewCartProductList,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/ads-section/all-ads-begin-checkout",
      component: BeginCheckoutProductList,
      meta: {
        middleware: [auth, admin],
      },
    },

    
    {
      path: "/admin/ads-section/all-ads-purchase",
      component: PurchaseProductList,
      meta: {
        middleware: [auth, admin],
      },
    },




  ]
};
export default router => {
  router.addRoute(moduleRoute);
  // console.log(router.getRoutes());
};
