import shopApi from '../composiables/shop-api.js';

const actions = {

    async addProductToCard({ commit, dispatch }, { product, quantity, session_id, shopdata, size }) {

        commit('SET_PRODUCT_CART_DATA', { product, quantity, session_id, shopdata });

        await shopApi.create(product, quantity, session_id, size);

        const payload = {
            type: 'success',
            message: 'Product add to cart successfully'
        }
        dispatch('addNotification', payload, { root: true });

    },

    async minusProductQtnA({ commit, dispatch }, { product, session_id, quantity, type }) {
        commit('MINUS_PRODUCT_QTNA', { product });

        await shopApi.update(product._id, session_id, type);
        const payload = {
            type: 'success',
            message: 'Product cart updated successfully'
        }
        dispatch('addNotification', payload, { root: true });
    },
    async plusProductQtnA({ commit, dispatch }, { product, session_id, type }) {
        commit('PLUS_PRODUCT_QTNA', { product });
        await shopApi.update(product._id, session_id, type);

        const payload = {
            type: 'success',
            message: 'Product cart updated successfully'
        }
        dispatch('addNotification', payload, { root: true });
    },

    async getCartItem({ commit }, { session_id }) {
        const cart = await shopApi.getAll(session_id);
        if (Array.isArray(cart.data)) {
            if (cart.data.length) {
                commit('SET_CART_ITEM', cart.data);
            }
        } else {
            commit('SET_CART_ITEM', cart.data);
        }

    },
    async removeProuctCart({ commit, dispatch }, { product, session_id }) {
        await shopApi.delete(product._id, session_id);
        commit('REMOVE_CART_ITEM', product);
        const payload = {
            type: 'failure',
            message: 'Product cart deleted successfully'
        }
        dispatch('addNotification', payload, { root: true });
    },

    async cartView({ commit, dispatch }){
        commit('cartView');
    }


}
export default actions;