<template>
<teleport to='body'>
    <div @click="$emit('close')"></div>
    <dialog open>
        <header>
            <slot name="header">
                <h2>{{title}}</h2>
            </slot>
        </header>
        <section>
            <slot>

            </slot>
        </section>
        <menu>
            <slot name="actions">
               
            </slot>
        </menu>
    </dialog>
</teleport>
</template>

<script>
export default {
    props:{
        title:{
            type: String,
            required: false
        }
    },
    emits:['close']
}
</script>

<style scoped lang="scss" >
    div{
        position: fixed;
        top: 0;left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.75);
        z-index: 10;
    }
    dialog{
        position: fixed;
        top: 20vh;
        left: 50%;
        transform: translateX(-50%);
        max-width: 42rem;
        width: 100%;
        z-index: 100;
        border-radius: 12px;
        border: none;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .26);
        padding: 0;
        // overflow: hidden;
        background: transparent;
    }
    header{
        background-color: #052c41 !important;
        color: white;
        width: 100%;
        padding: 1rem;
    }
    header h2{
        margin: 0;
    }
    section{
        padding: 1rem;
        background: white;
    }
    menu{
        padding: 1rem;
        display: flex;
        justify-content: flex-end;
        margin: 0;
        background: white;
    }
    
</style>