<template>
  <div class="gfyh">
    <div class="main_percenage_class">
      <div class="div-main">
        <div class="progress-bar" v-if="c">
          <div class="background-bar"></div>
          <transition appear @before-appear="beforeEnter" @after-appear="enter">
            <div class="tracker-bar" :class="{active: payment}"></div>
          </transition>
        </div>

        <div class="progress-bar" v-else>
          <div class="background-bar"></div>
          <transition appear @before-appear="beforeEnter" @after-appear="enter">
            <div class="tracker-bar" :class="{active: payment}"></div>
          </transition>
        </div>
      </div>
      <div class="total_percentage">{{total}}%</div>
    </div>
    <div class="value">
      <p
        class="notice-font"
      >After Account complete 20%. Qrcode distribute 20%. 25 product add 25% and Minimum topup 100tk 35%</p>
    
    </div>
  </div>
</template>
<script>

export default {
  // props: ["payment", "qrcode", "id"],

  props: {
    percentage: Number,
    label: String,
    payment: Number,
    qrcode: Number,
    id: String,
    product: Number
  },

  data() {
    return {
      c: 0
    };
  },

  methods: {
    beforeEnter(el) {
      el.style.width = 0;
    },
    enter(el) {
      el.style.width = `${this.total}%`;
      el.style.transition = `width 1s linear`;
    }
  },

  mounted() {},

  computed: {
    // a computed getter
    total: function() {
      // `this` points to the vm instance
      if (this.qrcode) {
        if (this.product > 25) {
          return 20 + this.payment * 1 * 35 + this.qrcode * 1 * 20 + 25;
        } else {
          return (
            20 + this.payment * 1 * 35 + this.qrcode * 1 * 20 + this.product
          );
        }
      } else {
        if (this.product > 25) {
          return 20 + this.payment * 1 * 35 + 25;
        } else {
          return 20 + this.payment * 1 * 35 + this.product;
        }
      }
    }
  }

  // setup(props) {
  //   const product = ref(0);
  //   const c = ref(0);

  //   onMounted(() => {
  //     axios
  //       .get("/api/user-section/employee-work-history-product/" + props.id)
  //       .then(res => {
  //         c.value = res.data;
  //         if (product.value <= 25) {
  //           product.value = (res.data * 1 * 100) / 25;
  //         } else {
  //           product.value = 100;
  //         }
  //       });
  //   });

  //   const totalPercentage = computed(() => {
  //     if (props.qrcode) {
  //       return 20 + props.payment * 1 * 35 + props.qrcode * 1 * 20 + c.value;
  //     } else {
  //       return 20 + props.payment * 1 * 35 + c.value;
  //     }
  //   });

  //   return {
  //     product,
  //     totalPercentage
  //   };
  // },
};
</script>
<style lang="scss" scoped>
.main_percenage_class {
  display: flex;
  column-gap: 8px;
  max-width: 330px;
  width: 100%;

  .notice-font {
    font-size: 11px;
  }
}
.div-main {
  width: 80%;
  .bar {
    height: 15px;
    border-radius: 5px;
    overflow: hidden;

    div {
      width: 25%;
      height: 100%;
      float: left;
    }
    .gray_class {
      background-color: #ddd;
    }
    .red {
      .red_inner_active {
        width: 100%;
        background: tomato;
      }

      .red_inner {
        width: 70%;
        background: blue;
      }

      .red_inner_qrcode {
        width: 100%;
        background: red;
      }
      .red_inner_payment {
        width: 100%;
        background: red;
      }
    }

    .green {
      background-color: green;
    }
  }
  .value {
    div {
      width: 25%;
      height: 100%;
      float: left;
      font-size: 12px;
      line-height: 20px;
      p {
        // padding-left: 5px;
      }
    }
  }
}
.total_percentage {
  width: 20%;
}
.gfyh {
  .value{
    .notice-font{
      font-size: 12px;
    }
  }
}

$phone-width-small: 360px;
$phone-width: 575px;
$tablet-width: 767px;
$custom-width: 991px;
$desktop-width: 1023px;

@media (max-width: #{$desktop-width}) {
  .gfyh {
    .value{
      .notice-font{
        width: 100px;
        overflow: auto;
      }
    }
  }
}
</style>



<style lang="scss" scoped>
$greydark: #c0cac9;
$black: #333;
$screen: #eff3f4;
$pink: #ff298a;
$grey: #dedfe0;

body {
  background: $screen;
  font-family: "Montserrat", sans-serif;
}

#app {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  height: 100vh;
}
// .container {
//   width: 400px;
//   height: 90px;
//   display: inline-grid;
//   align-items: center;
// }

.progress-bar {
  width: 100%;
  .active {
    background: green !important;
  }
  label {
    color: grey;
  }
  .info {
    font-size: 17px;
    justify-content: space-between;
    display: flex;
    color: grey;
    text-transform: uppercase;
    .percentage {
      font-weight: bolder;
    }
  }
  .background-bar {
    background: #ccc;
    width: 100%;
    height: 10px;
  }

  .tracker-bar {
    background: $pink;
    margin-top: -10px !important;
    height: 10px;
    width: 0;
    transition: width 0.5s linear;
    height: 10px;
  }
}
</style>
<style lang="scss" scope>
$purple: #800080;
$nav-bg: #f5f7fa;
$white: #ffffff;
$orange: #ffb715;
$blue: #016ba8;
$muted: #707070;

// .container {
//   margin-top: 50px;
//   padding: 25px;
//   background: $nav-bg;
//   border-radius: 5px;
// }

p {
  color: $muted;
}

.fa-info-circle {
  color: $blue;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    color: $orange;
    transition: 1s all;
  }
}

.info-class {
  background: $blue;
  color: $white !important;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.55);
  width: 30%;
  position: absolute;
  z-index: 500;
  p {
    color: #ffffff;
    font-size: 13px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>





