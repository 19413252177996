const getters ={
    cartItemCount: state => {
        let totalItems = 0;
        for (const profileId in state.cart) {
          totalItems += state.cart[profileId].reduce((acc, item) => acc + item.quantity, 0);
        }
        return totalItems;
      },

    cartTotalPrice(state){
        let total = 0;
        const cartArray = Object.values(state.cart); // Convert proxy object to array
    
        cartArray.forEach(items => {
            items.forEach(item =>{
                const salePrice = item?.product?.product_info?.sale_price ?? item?.product?.sale_price;
                if (salePrice) {
                    total += salePrice * item.quantity;
                }
            })
           
        });
    
        return total;
    },

    totalShop(state){
      const cartArray = Object.values(state.cart);
      return cartArray.length;
    },

    cartView(state){
      return state.cart_view ;
    },

    cartItemCountP: (state) => (profileId) => {
        let totalItems = 0;
        if (state.cart[profileId]) {
          totalItems = state.cart[profileId].reduce((acc, item) => acc + item.quantity, 0);
        }
        return totalItems;
      },

      cartTotalPriceP: (state) => (profileId) => {
        let total = 0;
        const profileCart = state.cart[profileId];
      
        if (profileCart) {
          profileCart.forEach(item => {
            const salePrice = item?.product?.product_info?.sale_price ?? item?.product?.sale_price;
            if (salePrice) {
              total += salePrice * item.quantity;
            }
          });
        }
      
        return total;
      },


}

export default getters;