export default() => ({
    data:{},
    cart: {},
    cart_view: false
});


// const state ={
//     data:{},
// }

// export default state;