import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const ShopTextModules = () => import('./ShopTextModules.vue');
const ShopTextPage = () => import('./page/ShopTextPage.vue');
const ShopTextAddPage = () => import('./page/ShopTextAddPage.vue');
const ShopTextEditPage = () => import('./page/ShopTextEditPage.vue');
//inventory section
const InventoryTextAddPage = () => import('./page/InventoryTextAddPage.vue');
const InventoryTextPage = () => import('./page/InventoryTextPage.vue');
const InventoryTextEditPage = () => import('./page/InventoryTextEditPage.vue');


const moduleRoute = {
    path: "/admin/shop_text_section",
    component: ShopTextModules,
    meta:{
        middleware:[auth, admin],
      },
  
    children:[
        {
            path: "/admin/shop_text_section",
            component: ShopTextPage,
            meta:{
                middleware:[auth, admin],
              },
        },
        {
          path: "/admin/shop_text_section/add_shop_text",
          component: ShopTextAddPage,
          meta:{
              middleware:[auth, admin],
            },
      },
      {
        path: "/admin/shop_text_section/edit_shop_text/:id",
        component: ShopTextEditPage,
        meta:{
            middleware:[auth, admin],
          },
      },

      {
        path: "/admin/inventory_text_section/add_inventory_text",
        component: InventoryTextAddPage,
        meta:{
            middleware:[auth, admin],
          },
      },

      {
        path: "/admin/inventory_text_section/edit_shop_text/:id",
        component: InventoryTextEditPage,
        meta:{
            middleware:[auth, admin],
          },
      },

      {
        path: "/admin/inventory_text_section",
        component: InventoryTextPage,
        meta:{
            middleware:[auth, admin],
          },
      },



    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  