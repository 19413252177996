<template>
    <div>
        <span v-if="image_type == 'Capsule'" >
            <p><img class="image" v-if="image_type == 'Capsule'" src="@/assets/farmacy/Capsule.jpg" /></p>
            <p>{{ product_type }}</p>
        </span>

        <span v-else-if="image_type == 'Drops'">
            <p><img class="image" v-if="image_type == 'Drops'" src="@/assets/farmacy/Drops.jpg" /></p>
            <p>{{ product_type }}</p>

        </span>
        <span v-else-if="image_type == 'Injection'">
            <p> <img class="image" v-if="image_type == 'Injection'" src="@/assets/farmacy/Injection.jpg" /></p>
            <p>{{ product_type }}</p>

        </span>
        <span v-else-if="image_type == 'Tablet'">
            <p><img class="image" v-if="image_type == 'Tablet'" src="@/assets/farmacy/Tablet.jpg" /></p>
            <p>{{ product_type }}</p>
        </span>
        <span v-else-if="image_type == 'Inhaler'">
            <p><img class="image" v-if="image_type == 'Inhaler'" src="@/assets/farmacy/Inhaler.jpg" /></p>
            <p>{{ product_type }}</p>
        </span>
        <span v-else-if="image_type == 'Paediatrice Drops'">
            <p><img class="image" v-if="image_type == 'Paediatrice Drops'" src="@/assets/farmacy/Paediatrice-drops.jpg" /></p>
            <p>{{ product_type }}</p>
        </span>
        <span v-else-if="image_type == 'Jel'">
            <p><img class="image" v-if="image_type == 'Jel'" src="@/assets/farmacy/Jel.jpg" /></p>
            <p>{{ product_type }}</p>
        </span>
        <span v-else-if="image_type == 'Ointment'">
            <p><img class="image" v-if="image_type == 'Ointment'" src="@/assets/farmacy/Ointment.webp" /></p>
            <p>{{ product_type }}</p>
        </span>
        <span v-else-if="image_type == 'Suspention'">
            <p><img class="image" v-if="image_type == 'Suspention'" src="@/assets/farmacy/Suspention.jpg" /></p>
            <p>{{ product_type }}</p>
        </span>
        
        <span v-else-if="image_type == 'Syrup'">
            <p><img class="image" v-if="image_type == 'Syrup'" src="@/assets/farmacy/Syrup.jpg" /></p>
            <p>{{ product_type }}</p>
        </span>
        <span v-else-if="image_type == 'Suppository'">
            <p><img class="image" v-if="image_type == 'Syrup'" src="@/assets/farmacy/suppositories.png" /></p>
            <p>{{ product_type }}</p>
        </span>
        <span v-else-if="image_type == 'Spray'">
            <p><img class="image" v-if="image_type == 'Syrup'" src="@/assets/farmacy/spray.png" /></p>
            <p>{{ product_type }}</p>
        </span>




        <span v-else>
            <p>{{ product_type }}</p>
        </span>
      
    </div>
</template>

<script>
    export default{
        props:['image_type', 'product_type']
    }
</script>

<style scoped>
    .image{
        width: 30px;
        height: 30px;
    }
</style>