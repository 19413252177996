import Axios from 'axios';
const RESOURCE_NAME = '/api/user/profile/shop';

export default{



    //  getServiceProfileList() {
    //     return Axios.get(`api/user/user_service_profile_list`);
    //   },
    checkout(data){
      return Axios.post(`${RESOURCE_NAME}/checkout`, data);
    },
    
    checkoutP(data){
      return Axios.post(`${RESOURCE_NAME}/checkoutp`, data);
    },
    
    create(product, quantity, session_id, size) {
      return Axios.post(`${RESOURCE_NAME}/add_cart`, {product, quantity, session_id,size});
    },

    getDistrict() {
      return Axios.get(`${RESOURCE_NAME}/get_district_data`);
    },

    getAllShopProduct(id, data) {
        return Axios.get(`${RESOURCE_NAME}/${id}}?page=${data}`);
      },

      getAllOfferProduct(id) {
        return Axios.get(`/api/mypanel/service/profile-shop/all-offer/${id}`);
      },

      getAll(session_id) {
        return Axios.get(`${RESOURCE_NAME}-cart/${session_id}`);
      },

      getShopProductInfo(slug, page) {
        return Axios.get(`/api/mypanel/service/profile-shop/${slug}?page=${page}`);
      },

      getShopSimilarProduct(slug, page) {
        return Axios.get(`/api/mypanel/service/profile-shop-similar-product/${slug}?page=${page}`);
      },

      sendOtp(data){
        return Axios.post(`${RESOURCE_NAME}/send-top`, data);
      },


    //   getInfo(id) {
    //     return Axios.get(`${RESOURCE_NAME}/${id}`);
    //   },

    //   payNow(data) {
    //     return Axios.post(`${RESOURCE_NAME}/paynow`, data);
    //   },

    //   shopActiveOff(data) {
    //     return Axios.post(`${RESOURCE_NAME}/activeoff`, data);
    //   },

     

      update(id, session_id, type) {
        return Axios.post(`${RESOURCE_NAME}/${id}/${session_id}/${type}`);
      },
      delete(id, session_id) {
        return Axios.delete(`${RESOURCE_NAME}/${id}/${session_id}`);
      },

      

      getServiceProfileInfo(id) {
        return Axios.get(`/api/mypanel/service/profile-shop/service_profile_info/${id}`);
      },

      sendUserInformation(data) {
        return Axios.post(`${RESOURCE_NAME}/send_user_information`, data);
      },

      getInvoiceOrNot() {
        return Axios.get(`/api/user/profile/shop/get_user_invoice_or_not`);
      },
      
      getAddToCartData(id) {
        return Axios.get(`/api/user/profile/shop/get_add_to_cart_data/${id}`);
      },
      
}


