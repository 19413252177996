<template>
  <router-view></router-view>
  <notification-list></notification-list>
</template>

<script>
import NotificationList from './components/notification/NotificationList.vue';
import User from './middleware/User';
export default{

 
  components:{
    NotificationList
  },

  mounted(){

    User.hasToken();
    window.addEventListener('pageshow', function(event) {
    if (event.persisted) {
        console.log("scrollfix");
        window.scrollTo(0, 1);
        window.scrollTo(0, 0);
    }
});

this.$nextTick(() => {
      window.scrollTo(0, 1)
      window.scrollTo(0, 0)
});

  // (function (w, d, s, l, i) {
  //     w[l] = w[l] || [];
  //     w[l].push({
  //       'gtm.start': new Date().getTime(),
  //       event: 'gtm.js'
  //     });
  //     var f = d.getElementsByTagName(s)[0],
  //       j = d.createElement(s),
  //       dl = l != 'dataLayer' ? '&l=' + l : '';
  //     j.async = true;
  //     j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  //     f.parentNode.insertBefore(j, f);
  //   })(window, document, 'script', 'dataLayer', 'GTM-P4FKGTP5');


  },
  created(){
    User.hasToken();
    var nowTime = new Date().getTime();
    if(localStorage.getItem('expaire')){
      if( nowTime >= localStorage.getItem('expaire') * 1000){
        User.logOut();
      }
    }
    

  }

}

</script>
