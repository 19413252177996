<template>
    <div id="components-app">
        <ul class="pagination">
            <li class="page-item">
                <a class="page-link" @click="onPageBack">
                   &#60;
                </a>
            </li>
            <template v-for="(i, y) in pages">
                <li v-if="y < maxVisible" :key="'pagination-page-' + i" :class="{ 'page-item': 1, active: modelValue == i }">
                    <a class="page-link" @click="onPageClick(i)">{{ i }}</a>
                </li>
            </template>

            <li class="page-item" >
                <a class="page-link" @click="onPageForward">
                   &#62;
                </a>
            </li>
        </ul>
    </div>
</template>


<script>

export default{
      props: {
        modelValue: {
            type: Number,
            default: () => 1,
        },
        rowsNumber: {
            type: Number,
            default: () => 0,
        },
        rowsPerPage: {
            type: Number,
            default: () => 0,
        },
        maxVisible: {
            // Max buttons in a row
            type: Number,
            default: () => 5,
        },
    },

    // mounted() {
    //     t
    // },


     computed: {
        pages() {
            function range(start, end) {
                return Array.from(Array(end - start + 1), (_, i) => i + start);
            }

            const max = this.maxVisible;
            const middle = Math.floor(this.maxVisible / 2);
            const pageNum = Math.ceil(this.rowsNumber / this.rowsPerPage);

            if (pageNum < max) {
                return range(1, pageNum);
            }

            let start = this.modelValue - middle;
            let end = this.modelValue + middle;

            // If we're close to the end
            if (this.modelValue >= pageNum - middle) {
                start = pageNum - max + 1;
                end = pageNum;
            }

            return range(Math.max(1, start), Math.max(end, max));
        },


       

        //  pages() {
        //     function range(start, end) {
        //         return Array.from(Array(end - start + 1), (_, i) => i + start);
        //     }

        //     const max = 5;
        //     const middle = Math.floor(5 / 2);
        //     const pageNum = Math.ceil(50 / 10);

        //     if (pageNum < max) {
        //         return range(1, pageNum);
        //     }

        //     let start = this.modelValue - middle;
        //     let end = this.modelValue + middle;

        //     // If we're close to the end
        //     if (this.modelValue >= pageNum - middle) {
        //         start = pageNum - max + 1;
        //         end = pageNum;
        //     }

        //     return range(Math.max(1, start), Math.max(end, max));
        // },
    },


    methods: {
        onPageClick(page) {
            this.$emit('update:modelValue', page);
        },
        onPageBack() {
            if (this.modelValue - 1 >= 1) {
                this.onPageClick(this.modelValue - 1);
            }
        },
        onPageForward() {
            if (
                this.modelValue + 1 <=
                Math.ceil(this.rowsNumber / this.rowsPerPage)
            ) {
                this.onPageClick(this.modelValue + 1);
            }
        },
    },
}

</script>

<style lang="scss">
    *, ::after, ::before {
    box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

#components-app {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    > table {
        border: 2px solid rgba(#444, 50%);
        border-radius: 5px;
        cell-padding: 0;
        border-spacing: 0;
        
        > thead {
            border-bottom: 1px solid rgba(#444, 50%);
            > tr > th {
                padding: 10px 12px;
                margin-top: -4px;
                
                &:not(:last-child) {
                    border-right: 1px solid #ddd;
                }
            }
        }
        
        > tbody {
            > tr {
                &:nth-child(odd) {
                    td {
                        background-color: #ddd;
                    }
                }
                
                > td {
                    padding: 6px 5px;
                    
                    &:not(last-child) {
                        border-right: 1px solid #ddd;
                    }
                }
            }
        }
    }
}

ul.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    
    li.page-item {
        line-height: 24px;
        margin-right: .5rem;
        
        a.page-link {
            padding: 0;
            width: 40px;
            height: 40px;
            display: block;
            font-size: 1rem;
            line-height: 40px;
            user-select: none;
            position: relative;
            text-align: center;
            border-radius: 50%;
            border: 2px solid #999;
            box-sizing: content-box;
            background: transparent;
        }
            
        &.active >
        a.page-link,
        > a.page-link:hover {
            color: #fff;
            cursor: pointer;
            border-color: #028ce8;
            background-color: #028ce8;
        }
    }
}







$phone-width-small: 360px;
$phone-width: 575px;
$tablet-width: 767px;
$custom-width: 991px;
$desktop-width: 1023px;

@media (max-width: #{$desktop-width}) { 
}
@media (max-width: #{$custom-width}) { 
}
@media (max-width: #{$tablet-width}) {
    ul.pagination {
        li.page-item {
            a.page-link {
                width: 24px;
                height: 24px;
                line-height: 24px;
            }
        }
    }
}
@media (max-width: #{$phone-width}) {
    
}
@media (max-width: #{$phone-width-small}) {
}
</style>

