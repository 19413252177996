<template>
    <div class="vue_input" :class="[divClass]">
        <label :class="labelClass" v-if="label != ''">{{ label }} <span class="tooltip" v-if="title"><span class="title">{{titleText}}</span></span><span class="star" v-if="star" >*</span></label>
        <input class="field" :class="[classValue,{'errors':validation}]"  :min="min" :max="max" :type="type" :value="modelValue" :placeholder="placeholder" :name="name" :readonly="read"
            @input="$emit('update:modelValue', $event.target.value)" step=".01" />
              <span class="errorMessage" v-if="validation">{{validation}}</span>
    </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: '',
        },

         name: {
            type: String,
            default: '',
        },

         read: {
            type:Boolean,
            default: false,
        },

        modelValue: {
            type: [String, Number],
            default: '',
        },
        
        min: {
            type: [Number],
            default: 1,
        },
        max: {
            type: [Number],
            default:100000000000000,
        },

        placeholder: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        divClass: {
            type: [String, Number],
            default: '',
        },
        labelClass: {
            default: '',
        },
        classValue: {
            type: [String, Number],
            default: '',
        },
        title: {
            type:Boolean,
            default: false,
        },
        titleText: {
            type:[String, Number],
            default: '',
        },
         star: {
            type: Boolean,
            default:false,
     },
      validation:{
             type: String,
            default: '',
        }
    },
    setup() {

    },
}
</script>
<style lang="scss" scoped>
.m_td_input_class_65 {
    min-width: 65px !important;
    input {
        font-size: 14px !important;
        padding: 4px !important;
    }
}
.m_td_input_class_80 {
    min-width: 80px !important;
    input {
        font-size: 14px !important;
        padding: 4px !important;
    }
}
.m_td_input_class_100 {
    min-width: 100px !important;
    input {
        font-size: 14px !important;
        padding: 4px !important;
    }
}
.m_td_input_class_120 {
    min-width: 120px !important;
    input {
        font-size: 14px !important;
        padding: 4px !important;
    }
}
.m_td_input_class_140 {
    min-width: 140px !important;
    input {
        font-size: 14px !important;
        padding: 4px !important;
    }
}
.m_td_input_class_160 {
    min-width: 160px !important;
    input {
        font-size: 14px !important;
        padding: 4px !important;
    }
}
.m_td_input_class_180 {
    min-width: 180px !important;
    input {
        font-size: 14px !important;
        padding: 4px !important;
    }
}
.m_td_input_class_200 {
    min-width: 200px !important;
    input {
        font-size: 14px !important;
        padding: 4px !important;
    }
}
.f-11{
    font-size: 13px !important;
}
.star{
     color:red;
}
input.errors {
    border: 1px solid red!important;;
}

.errorMessage{
    color: tomato;
}
.shadow_none {
    box-shadow: none!important;
}
.vue_input {
    width: 100%;
    label {
        margin-bottom: 8px;
        display: inline-block;
        color: #555;
        font-size: 16px;
        // text-shadow: 0 1px 1px #5b8fce;
        .tooltip {
            position: relative;
            &:hover::after {
                border-color: #000;
                color: #000;
            }
            &:hover .title {
                display: block;
            }
            &::after {
                position: absolute;
                content: "!";
                left: 10px;
                width: 6px;
                height: 6px;
                border: 1px solid #444;
                color: tomato;
                border-radius: 50%;
                padding: 5px;
                line-height: 6px;
                text-align: center;
                cursor: help;
                display: inline-table;
                transition: all 0.3s ease 0s;
                -webkit-transition: all 0.3s ease 0s;
            }
            .title {
                position: absolute;
                top: -26px;
                left: -60px;
                width: 150px;
                font-size: 11px;
                background-color: #000;
                color: #fff;
                padding: 5px;
                border-radius: 4px;
                display: none;
                &::after {
                    position: absolute;
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 8px solid #000;
                    bottom: -6px;
                    left: 70px;
                }
            }
        }
    }
    input {
        width: 100%;
        height: 45px;
        border: none;
        outline: none;
        padding: 4px 16px;
        border-radius: 4px;
        color: #444;
        letter-spacing: 1px;
        font-size: 16px;
        box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45), 5px 5px 9px rgba(94, 104, 121, 0.3),-5px -5px 9px rgba(255, 255, 255, 0.45),
            5px 5px 9px rgba(94, 104, 121, 0.3);
        background-color: #f9f9f9;
        position: relative;
        &::placeholder {
            color: #999;
        }
        &:focus {
            border: 1px solid #23c483;
            box-shadow: none;
        }
    }

}


.price,
.unit,
.min_sale,
.stock {
    input {
        padding: 0;
        text-align: center;
    }
}
._h_34 {
    input {
        height: 34px;
        font-size: 14px;
    }
}
</style>