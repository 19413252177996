<template>
    <div class="border"></div>
</template>
<style lang="scss">
.border {
    max-width: 500px;
    border-bottom: 1px solid #ddd;
    text-align: center;
    position: relative;
    &::before,
    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 12px;
    }
    &::before {
        top: 0;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-radius: 20px;
    }
}
</style>