// import router from '@/router';

const MultiFormModules = () => import('./MultiFormModules.vue');
const MultiFormPageAdd = () => import('./page/MultiFormPage.vue');
const MultiFormPage = () => import('./page/MultiFormPageView.vue');
const MultiFormEditPage = () => import('./page/MultiFormEditPage.vue');

//inventory form page
const InventoryFormPage = () => import('./page/InventoryFormPage.vue');
const InventoryFormPageEdit = () => import('./page/InventoryFormPageEdit.vue');
const InventoryFormPageView = () => import('./page/InventoryFormPageView.vue');


const moduleRoute = {
    path: "/admin/multi-form",
    component: MultiFormModules,
  
    children:[
        {
            path: "/admin/multi-form",
            component: MultiFormPageAdd,
        },
        {
            path: "/admin/multi-form-view",
            component: MultiFormPage,
        },
        {
            path: "/admin/multi-form-edit/:gid/:id",
            component: MultiFormEditPage,
        },

        {
            path: "/admin/inventory-form-page",
            component: InventoryFormPage,
        },

        {
            path: "/admin/inventory-form-page-edit/:id",
            component: InventoryFormPageEdit,
        },
        {
            path: "/admin/inventory-form-page-view",
            component: InventoryFormPageView,
        },



    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  