import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const DeliveryModules = () => import('./DeliveryModules.vue');
const DeliveryPage = () => import('./page/DeliveryPage.vue');
const DeliveryAddPage = () => import('./page/DeliveryAddPage.vue');
const DeliveryEditPage = () => import('./page/DeliveryEditPage.vue');
const DeliveryPageCompany = () => import('./page/DeliveryPageCompany.vue');

const moduleRoute = {
    path: "/admin/delivery",
    component: DeliveryModules,
    meta:{
        middleware:[auth, admin],
      },
  
    children:[
        {
            path: "/admin/delivery",
            component: DeliveryPage,
            meta:{
                middleware:[auth, admin],
              },
        },
        {
            path: "/admin/delivery-company",
            component: DeliveryPageCompany,
            meta:{
                middleware:[auth, admin],
              },
        },


        {
          path: "/admin/delivery-add",
          component: DeliveryAddPage,
          meta:{
              middleware:[auth, admin],
            },
      },
      {
        path: "/admin/delivery-edit/:id",
        component: DeliveryEditPage,
        meta:{
            middleware:[auth, admin],
          },
    },
    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  