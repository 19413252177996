
const AdsModules = () => import('./AdsModules.vue');
const AdsAll = () => import('./comps/AdsAll.vue');
const AdsAdd = () => import('./comps/AdsAdd.vue');
const AdsEdit = () => import('./comps/AdsEdit.vue');
const moduleRoute = {
    path: "/user/ads",
    component: AdsModules,
  
    children:[
        {
            path: "/user/ads",
            component: AdsAll,
        },
        {
            path: "/user/ads/add",
            component: AdsAdd,
        },
        {
            path: "/user/ads/edit/:id",
            component: AdsEdit,
        },
    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  