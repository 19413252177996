<template>
  <img :src="imageUrl" :srcset="imageSrcSet" sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 1200px" alt="Description" />
</template>

<script>
export default {
  props: {
    imageUrlProp: {
      type: String,
      required: true,
    },
    extension: {
      type: String,
      required: true,
    },
  },
  computed: {
    imageUrl() {
      // You can process the dynamic URL here if needed
      return this.imageUrlProp;
    },
    imageSrcSet() {
      // Generate different size variations based on the dynamic URL
      // Example dynamic URLs for different sizes
      const imageUrl480w = this.imageUrl.replace(this.extension, '-480w'+this.extension);
      const imageUrl768w = this.imageUrl.replace(this.extension, '-768w'+this.extension);
      const imageUrl1200w = this.imageUrl; // Original size

      return `${imageUrl480w} 480w, ${imageUrl768w} 768w, ${imageUrl1200w} 1200w`;
    },
  },
};
</script>
