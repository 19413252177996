// import router from '@/router';

const CartModule = () => import('./CartModule.vue');
const CartPage = () => import('./views/CartPage.vue');
const CheckoutPage = () => import('./views/comps/CheckoutPage.vue');
const ObjectType = () => import('./views/Test.vue');

const moduleRoute ={
    path:'/cart',
    component: CartModule,
    children:[
        {
            path:"/cart",
            component: CartPage
        },
        {
            path:"/buy-checkout",
            component: CheckoutPage
        },
        {
            path:"/object-type",
            component: ObjectType
        }
    ]
}

export default router => {
    router.addRoute(moduleRoute);
  };
  