import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const CategoriesModules = () => import('./AdsSettingModules.vue');
const AdsSettingPage = () => import('./page/AdsSettingPage.vue');

const moduleRoute = {
    path: "/admin/ads-setting",
    component: CategoriesModules,
    meta:{
        middleware:[auth, admin],
    },
  
    children:[
        {
            path: "/admin/ads-setting",
            component: AdsSettingPage,
            meta:{
                middleware:[auth, admin],
            },
        },
    ]
};
export default router => {
    router.addRoute(moduleRoute);
  };
  