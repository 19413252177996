export default() => ({
    data:'',
    profile: ''
});


// const state ={
//     data:{},
// }

// export default state;