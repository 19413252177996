const mutations ={
    // SET_DATA(state, data) {
    //     state.data = data;
    // },
    SET_PRODUCT_CART_DATA(state, { product, quantity, session_id, shopdata }) {
        if (!state.cart[product.profile_id]) {
          // If the profile_id doesn't exist in cart, initialize it as an array
          state.cart[product.profile_id] = [];
        }
    
        const existingItemIndex = state.cart[product.profile_id].findIndex(item => {
          return item.product._id === product._id;
        });
    
        if (existingItemIndex !== -1) {
          // If the item already exists in the cart, update its quantity
          state.cart[product.profile_id][existingItemIndex].quantity += quantity;
        } else {
          // If the item doesn't exist in the cart, add it to the cart
          const newItem = {
            product,
            quantity,
            session_id,
            profiles: shopdata
          };
          state.cart[product.profile_id].push(newItem);
          console.log(state.cart);
        }
      },


    SET_CART_ITEM(state, cartItems){
        state.cart = cartItems;
    },


    MINUS_PRODUCT_QTNA(state, {product}){
        state.cart[product.profile_id].filter(item=>{
          return  item.product.id == product.id
        }).quantity--;

    },

    PLUS_PRODUCT_QTNA(state, {product}){
        state.cart[product.profile_id].filter(item=>{
            return  item.product.id == product.id
          }).quantity++;
    },

    REMOVE_CART_ITEM(state, product){
       const removeItem = state.cart[product.profile_id].filter(item=>{
            return  item.product.id != product.id
          });
          return { ...state.cart, removeItem}
    },
    cartView(state){
      return state.cart_view = !state.cart_view;
    }


}

export default mutations;