// import router from '@/router';
const PositionModules = () => import('./PositionModules.vue');
const RSMPage = () => import('./views/RSMPage.vue');
const SARSMPage = () => import('./views/rsm/Sa.vue');
const RaRSMPage = () => import('./views/rsm/Ra.vue');
const AMPage = () => import('./views/AMPage.vue');
const SAPage = () => import('./views/SAPage.vue');
const RaAMPage = () => import('./views/am/AmRa.vue');
// const SaPage = () => import('./views/sa/SaRa.vue');

const moduleRoute = {
    path: "/position-section",
    component: PositionModules,
  
    children:[

        {
            path: "/position-section",
            component: RSMPage,
        },
        {
            path: "/position-section-under-sa/:uid",
            component: SARSMPage,
        },
        {
            path: "/position-section-under-ra/:uid",
            component: RaRSMPage,
        },

        {
            path: "/position-section-am",
            component: AMPage,
        },
        {
            path: "/position-section-am-uder-ra/:uid",
            component: RaAMPage,
        },

        {
            path: "/position-section-sa",
            component: SAPage,
        }

    ]
};

export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  