import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const WorkStationModules = () => import('./WorkStationModules.vue');
const WorkStationList = () => import('./page/WorkStationList.vue');
const WorkStationAdd = () => import('./page/WorkStationAdd.vue');
const WorkStationEdit = () => import('./page/WorkStationEdit.vue');

const moduleRoute = {
    path: "/admin/workstation",
    component: WorkStationModules,
    meta:{
        middleware:[auth, admin],
      },
  
    children:[
        {
            path: "/admin/workstation",
            component: WorkStationList,
            meta:{
                middleware:[auth, admin],
              },
        },
        {
          path: "/admin/workstation/add",
          component: WorkStationAdd,
          meta:{
              middleware:[auth, admin],
            },
      },
      {
        path: "/admin/workstation/edit/:id",
        component: WorkStationEdit,
        meta:{
            middleware:[auth, admin],
          },
    },
    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  