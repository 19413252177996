import inventoryApi from '../page/api/inventory-api.js'
const actions = {
    async getInventoryAccess ({state, commit}, profile_id){

       if(state.data == '' || state.profile == ''){
           const cart =  await inventoryApi.getUserAccessCheck(profile_id);
           commit('GET_USR_ACCESS_CHECK', cart.data);
       }
    },
   

}
export default actions;