// import router from '@/router';

const FormModules = () => import('./FairModule.vue');
const FairList = () => import('./page/FairList.vue');
const FairAdd = () => import('./page/FairAdd.vue');
const FairEdit = () => import('./page/FairEdit.vue');
const FairShopList = () => import('./page/FairShopList.vue');
const FairShopAdd = () => import('./page/FairShopAdd.vue');
const FairShopEdit = () => import('./page/FairShopEdit.vue');

const moduleRoute = {
    path: "/admin/fair",
    component: FormModules,
  
    children:[
        {
            path: "/admin/fair",
            component: FairList,
        },
        {
            path: "/admin/fair/add",
            component: FairAdd,
        },
        {
            path: "/admin/fair/edit/:id",
            component: FairEdit,
        },
        {
            path: "/admin/fair-shop",
            component: FairShopList,
        },
        {
            path: "/admin/fair-shop/add",
            component: FairShopAdd,
        },
        {
            path: "/admin/fair-shop/edit/:id",
            component: FairShopEdit,
        },
    ]
};
export default router => {
    router.addRoute(moduleRoute);
};
  