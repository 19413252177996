// import router from '@/router';
const AdminModule = () => import('./AdminModule.vue');
const HomePage = () => import('./view/home/HomePage.vue');
const BalanceRequest = () => import('./view/balance/BalanceRequest.vue');
const BalanceRequestAccept = () => import('./view/balance/BalanceRequestAccept.vue');
const BalanceRequestCancle = () => import('./view/balance/BalanceRequestCancle.vue');

//withdraw request 
const WithdrawRequest = () => import('./view/balance/WithDrawRequest.vue');



const AdminQrcode = () => import('./view/qrcode/Adminqrcode.vue');
const AdminQrcodeList = () => import('./view/qrcode/AdminQrcodeList.vue');
const AdminPressQrcode = () => import('./view/qrcode/AdminPressQrcode.vue');
const AdminShopPrint = () => import('./view/qrcode/AdminShopPrint.vue');
const AdminQrcodeCustomList = () => import('./view/qrcode/AdminQrcodeCustomList.vue');



const DemoShopList = () => import('./view/demo/DemoShopList.vue');
const DemoShopAdd = () => import('./view/demo/DemoShopAdd.vue');
const DemoShopEdit = () => import('./view/demo/DemoShopEdit.vue');

// admin shop  dashboard section

const shopDashboard = () => import('./view/home/shop/ShopAdminDashboard.vue');
const AllShop = () => import('./view/home/shop/view/AllShop.vue');
const PaidAdminShop = () => import('./view/home/shop/view/PaidAdminShop.vue');
const UnpaidAdminShop = () => import('./view/home/shop/view/UnpaidAdminShop.vue');
const CompleteAdminShop = () => import('./view/home/shop/view/CompleteAdminShop.vue');
const UncompleteAdminShop = () => import('./view/home/shop/view/UncompleteAdminShop.vue');

// Shop Wise Product Data get
const ViewProduct = () =>import('./view/home/product/ViewProduct.vue');
const AddProduct = () =>import('./view/home/product/AddProduct.vue');
const EditProduct = () =>import('./view/home/product/EditProduct.vue');
// view all special product show now
const SpecialProduct = () => import('./view/home/product/specialproduct/ViewSpecialProduct.vue');

//admin all order section
const AllOrderGet = () => import('./view/home/shop/view/order/AllOrder.vue');
const IndividualOrderGet = () => import('./view/home/shop/view/order/IndividualOrder.vue');
const TodayOrderGet = () => import('./view/home/shop/view/order/TodayOrder.vue');


//admin community section
const AdminCommunityShop = () => import('./view/community/CommunityShop.vue');
// Employee Shop Section
const AdminEmployeeShop = () => import('./view/employee/EmployeeShop.vue');
const AdminEmployeeShopInfo = () => import('./view/employee/EmployeeShopInfo.vue');
const AdminEmployeeShopInfoEdit = () => import('./view/employee/EmployeeShopInfoEdit.vue');
const AdminEmployeeInventory = () => import('./view/employee/EmployeeInventory.vue');
const AllEmployeeWorkProgress = () => import('./view/employee/AllEmployeeWorkProgress.vue');


//all employee report
const  AdminEmployeeReportHistory = () => import('./view/employee/report/AllEmployeeReport.vue');
const  AdminEmployeeReportHistoryUser = () => import('./view/employee/report/EmployeeReport.vue');

// ad employee manager
const AddEmployeeManager = ()=> import('./view/employee/AddSellManager.vue');

//admin inventory category section
const AddInventoryCategory = () => import('./view/inventorycategory/AddInventoryCategory.vue');
const ViewInventoryCategory = () => import('./view/inventorycategory/ViewInventoryCategory.vue');
const EditInventoryCategory = () => import('./view/inventorycategory/EditInventoryCategory.vue');

// Inventory category
const AddInventoryUnit = () => import('./view/inventorycategory/AddInventoryUnit.vue');
const EditInventoryUnit = () => import('./view/inventorycategory/EditInventoryUnit.vue');
const ViewCategoryUnit = () => import('./view/inventorycategory/ViewCategoryUnit.vue');


const CustomerList = ()=> import('./view/customer/CustomerList.vue');
const IntroducerList = () => import('./view/customer/page/IntroducerList.vue');
const ServiceProfileList = () => import('./view/customer/page/ServiceProfileList.vue');
const ServiceProfileListRe = () => import('./view/customer/page/ServiceProfileListRe.vue');
const InventorySellList = () => import('./view/customer/page/InventorySellList.vue');

//bank loan list section
const BankLoanApplyList = () => import('./view/customer/page/BankLoanApplyList.vue');
const InsurenceApplyList = () => import('./view/customer/page/InsurenceApplyList.vue');

//
const EditCustomerList = () => import('./view/customer/page/EditCustomerList.vue');
const AdminRatingReview = () => import('./view/customer/page/AdminRatingReview.vue');

const AddArchiveProduct = () => import('./view/category/page/AddArchiveProduct.vue');
const EditArchiveProduct = () => import('./view/category/page/EditArchiveProduct.vue');
const GetArchiveProduct = () => import('./view/category/page/ViewArchiveProduct.vue');

// ads section
const AdminViewAdsLists = () => import('./view/home/adssection/AdminViewAdsLists.vue');
const AdminViewAdsProduct = () => import('./view/home/adssection/AdminViewAdsProduct.vue');


//keyword
const Addkeyword = () => import('./view/home/keyword/AddKeyword.vue');
const ProductPageSideBarPanel = ()=> import('./view/home/productpage/ProductPageSideBarPanel.vue');
const EditProductPageSideBarPanel = ()=> import('./view/home/productpage/EditProductPageSideBarPanel.vue');


import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const moduleRoute = {
    path: "/admin",
    component: AdminModule,
    meta:{
        middleware:[auth, admin],
      },
  
    children:[

       {
        path: "/admin",
        component: HomePage,
        meta:{
            middleware:[auth, admin],
          },
       },

       {
        path: "/admin/balance_request",
        component: BalanceRequest,
        meta:{
            middleware:[auth, admin],
          },
       },

       {
        path: "/admin/balance_request_accept",
        component: BalanceRequestAccept,
        meta:{
            middleware:[auth, admin],
          },
       },
       {
        path: "/admin/balance_request_cancle",
        component: BalanceRequestCancle,
        meta:{
            middleware:[auth, admin],
          },
       },

       // withdraw request here
       {
        path: "/admin/withdraw_request",
        component: WithdrawRequest,
        meta:{
            middleware:[auth, admin],
          },
       },



       {
        path: "/admin/qurcode",
        component: AdminQrcode,
        meta:{
            middleware:[auth, admin],
          },
       },
       {
        path: "/admin/qurcode-list",
        component: AdminQrcodeList,
        meta:{
            middleware:[auth, admin],
          },
       },
       {
        path: "/admin/qurcode/press",
        component: AdminPressQrcode,
        meta:{
            middleware:[auth, admin],
          },
       },

       
       {
        path: "/admin/qurcode/shop-print",
        component: AdminShopPrint,
        meta:{
            middleware:[auth, admin],
          },
       },


       {
        path: "/admin/qurcode/custom-list",
        component: AdminQrcodeCustomList,
        meta:{
            middleware:[auth, admin],
          },
       },



       {
        path: "/admin/non-verified-shop-list",
        component: DemoShopList,
        meta:{
            middleware:[auth, admin],
          },
       },
       {
        path: "/admin/non-verified-shop-add",
        component: DemoShopAdd,
        meta:{
            middleware:[auth, admin],
          },
       },

       {
        path: "/admin/non-verified-shop-edit/:id",
        component: DemoShopEdit,
        meta:{
            middleware:[auth, admin],
          },
       },

       // employe shop section 

       {
        path: "/admin/shop-details",
        component: shopDashboard,
        meta:{
            middleware:[auth, admin],
          },
       },

       {
        path: "/admin/shop-details/all-shop",
        component: AllShop,
        meta:{
            middleware:[auth, admin],
          },
       },

       {
        path: "/admin/shop-details/paid-admin-shop",
        component: PaidAdminShop,
        meta:{
            middleware:[auth, admin],
          },
       },

       {
        path: "/admin/shop-details/unpaid-admin-shop",
        component: UnpaidAdminShop,
        meta:{
            middleware:[auth, admin],
          },
       },

       {
        path: "/admin/shop-details/complete-admin-shop",
        component: CompleteAdminShop,
        meta:{
            middleware:[auth, admin],
          },
       },

       {
        path: "/admin/shop-details/un-complete-admin-shop",
        component: UncompleteAdminShop,
        meta:{
            middleware:[auth, admin],
          },
       },
       {
        path: "/admin/community-shop-section",
        component: AdminCommunityShop,
        meta:{
            middleware:[auth, admin],
          },
       },
       {
        path: "/admin/employee-shop",
        component: AdminEmployeeShop,
        meta:{
            middleware:[auth, admin],
          },
       },


       {
        path: "/admin/employee-shop/info/:id",
        component: AdminEmployeeShopInfo,
        meta:{
            middleware:[auth],
          },
       },


       {
        path: "/admin/employee-shop/info/edit/:shopid/:id",
        component: AdminEmployeeShopInfoEdit,
        meta:{
            middleware:[auth, admin],
          },
       },
       {
        path: "/admin/employee-shop/info-work-progress-bar",
        component: AllEmployeeWorkProgress,
        meta:{
            middleware:[auth, admin],
          },
       },

       {
        path: "/admin/employee-shop-inventory/:id",
        component: AdminEmployeeInventory,
        meta:{
            middleware:[auth],
          },
       },



       {
        path: "/admin/all-employee-report-history",
        component: AdminEmployeeReportHistory,
        meta:{
            middleware:[auth],
          },
       },



       {
        path: "/admin/employee-report-history/:id",
        component: AdminEmployeeReportHistoryUser,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/employee-manager-add",
        component: AddEmployeeManager,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/inventory-category-section/add",
        component: AddInventoryCategory,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/inventory-category-section/view",
        component: ViewInventoryCategory,
        meta:{
            middleware:[auth],
          },
       },

       
       {
        path: "/admin/inventory-category-section/edit/:id",
        component: EditInventoryCategory,
        meta:{
            middleware:[auth],
          },
       },


       {
        path: "/admin/inventory-category-unit/add",
        component: AddInventoryUnit,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/inventory-category-unit/edit/:id",
        component: EditInventoryUnit,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/inventory-category-unit/view",
        component: ViewCategoryUnit,
        meta:{
            middleware:[auth],
          },
       },


      
       {
        path: "/admin/user-list-section",
        component: CustomerList,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/rating-review-list-section",
        component: AdminRatingReview,
        meta:{
            middleware:[auth],
          },
       },


       {
        path: "/admin/user-list-section/:uid",
        component: IntroducerList,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/serivice-list-section/:uid",
        component: ServiceProfileList,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/refferal-service-list-section/:uid",
        component: ServiceProfileListRe,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/inventory-sell-list-section/:uid",
        component: InventorySellList,
        meta:{
            middleware:[auth],
          },
       },
      
       {
        path: "/admin/customer-list-section_edit/:uid",
        component: EditCustomerList,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/bank-loan-apply-list",
        component: BankLoanApplyList,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/insurence-apply-list",
        component: InsurenceApplyList,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/add-archive-product",
        component: AddArchiveProduct,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/edit-archive-product/:id",
        component: EditArchiveProduct,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/get-archive-product",
        component: GetArchiveProduct,
        meta:{
            middleware:[auth],
          },
       },

       //shop wise product get ViewProduct

       {
        path: "/admin/service-profile/product/:spid",
        component: ViewProduct,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/service-profile/add-product/:spid",
        component: AddProduct,
        meta:{
            middleware:[auth],
          },
       },
       {
        path: "/admin/service-profile/edit-product/:spid/:id",
        component: EditProduct,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/service-profile/view-special-product",
        component: SpecialProduct,
        meta:{
            middleware:[auth],
          },
       },
      
       {
        path: "/admin/service-profile/all-order",
        component: AllOrderGet,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/service-profile/individual-order/:spid",
        component: IndividualOrderGet,
        meta:{
            middleware:[auth],
          },
       },
       {
        path: "/admin/service-profile/today-order",
        component: TodayOrderGet,
        meta:{
            middleware:[auth],
          },
       },



       {
        path: "/admin/home/view-all-ads-lists",
        component: AdminViewAdsLists,
        meta:{
            middleware:[auth],
          },
       },
       {
        path: "/admin/home/view-all-ads-lists-product/:id",
        component: AdminViewAdsProduct,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/home/add-keyword",
        component: Addkeyword,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/home/product-page-sidebar-panel",
        component: ProductPageSideBarPanel,
        meta:{
            middleware:[auth],
          },
       },

       {
        path: "/admin/home/product-page-sidebar-panel-edit/:id",
        component: EditProductPageSideBarPanel,
        meta:{
            middleware:[auth],
          },
       },




      
    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  