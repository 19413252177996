const getters ={

    // cartItemCount (state){
    //     return state.cart.length;
    // },


    // cartTotalPrice(state){
    //     let total = 0;
    //     state.cart.forEach(item => {
    //         if(item.product.product_info){

    //             total += item.product.product_info.sale_price * item.quantity;
    //         }else{
    //             total += item.product.sale_price * item.quantity;
    //         }
    //     });
    //     return total;
    // }




}

export default getters;