// import router from '@/router';

const ProductModule = () => import('./ProductModule.vue');
// const ProductPage = () => import('./views/ProductPage.vue');
const ProductCatViewNew = () => import('./views/ProductCatViewNew.vue');
const ProductCatIdViewNew = () => import('./views/ProductCatIdViewNew.vue');
const ProductCategoryPage = () => import('./views/ProductCategoryPage.vue');
const ProductDetailsPage = () => import('./views/ProductDetailsPage.vue');
const SimilarProduct = () => import('./views/SimilarProduct.vue');
//search section
const SearchProductCatViewNew = () => import('./views/SearchProductCatViewNew.vue');
//sidebar produdct
const SidebarProductCatViewNew = () => import('./views/comps/SidebarProductCatViewNew.vue');
const SidebarSkuProductCatViewNew = () => import('./views/comps/SidebarSkuProductCatViewNew.vue');


const moduleRoute = {
    path: "/products",
    component: ProductModule,
  
    children:[
    //    {
    //     path: "/products",
    //     component: ProductPage,
    //    },
       {
        path: "/products",
        component: ProductCatViewNew,
       },

       {
        path: "/products-search",
        component: SearchProductCatViewNew,
        props: (route) => ({ query: route.query.search }),
       },


       {
        path: "/products/cat/:cid",
        component: ProductCatIdViewNew,
       },
       {
        path: "/products/category",
        component: ProductCategoryPage,
       },
       {
        path: "/products/details/:id",
        component: ProductDetailsPage,
       },
       
       {
        path: "/products/similar/:id/:pdt_name",
        component: SimilarProduct,
       },

       {
        path: "/product-category/:slug",
        component: SidebarProductCatViewNew,
       },
       {
        path: "/product-category/:slug/:id",
        component: SidebarSkuProductCatViewNew,
       },



    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  