// import router from '@/router';
const ApplyModules = () => import('./ApplyModules.vue');
const ApplyPage = () => import('./views/ApplyPage.vue');
const EarningPage = () => import('./views/EarningPage.vue');

const LocalListPage = () => import('./localhost/LocalListPage.vue');
const LocalFormPage = () => import('./localhost/LocalFormPage.vue');
const LocalPdfPage = () => import('./localhost/LocalPdfPage.vue');
const LocalViewPage = () => import('./localhost/LocalViewPage.vue');

const AssociatePartner = () => import('./localhost/AssociatePartner.vue');
const StrategicPartner = () => import('./localhost/StrategicPartner.vue');
const MicroFreelancing = () => import('./localhost/MicroFreelancing.vue');
// home page tutorial section
const Tutorial = () => import('./views/TutorialSection.vue');

import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const moduleRoute = {
    path: "/apply",
    component: ApplyModules,
  
    children:[
        {
            path: "/apply",
            component: ApplyPage,
        },
        {
            path: "/earning",
            component: EarningPage,
        },


        {
            path: "/apply/localhost",
            component: LocalListPage,
            meta:{
                middleware:[auth, admin],
              },
        },
        
        {
            path: "/apply/micro-freelancing/form",
            component: MicroFreelancing,
            meta:{
                middleware:[auth, admin],
              },
        },


        {
            path: "/apply/localhost/view/:id",
            component: LocalViewPage,
            meta:{
                middleware:[auth, admin],
              }
        },

        {
            path: "/apply/localhost/form",
            component: LocalFormPage,
            meta:{
                middleware:[auth],
              }
        },

        {
            path: "/apply/associate-partner/form",
            component: AssociatePartner,
            meta:{
                middleware:[auth],
              }
        },

        {
            path: "/apply/strategic-partner/form",
            component: StrategicPartner,
            meta:{
                middleware:[auth],
              }
        },


        {
            path: "/apply/localhost/pdf",
            component: LocalPdfPage,
            meta:{
                middleware:[auth],
              }
        },
        {
            path: "/tutorial",
            component: Tutorial,
            meta:{
                middleware:[auth],
              }
        },




    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  