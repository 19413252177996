<template>
  <div>heloo</div>
  <!-- <div class="modal_container show" @click="PopupHide">
    <div class="dialog popup_show">
      <div class="head">
        <select name id="mode">
          <option value="DRIVING" selected>Driving</option>
          <option value="WALKING">Walking</option>
          <option value="BICYCLING">Bicycling</option>
          <option value="TRANSIT">Transit</option>
        </select>
        <div class="close_btn" @click="PopupHides">
          <base-button small="btn" type="button">Close</base-button>
        </div>
      </div>

      <div class="body">
        <div class="content">
          <div
            class="map"
            ref="oracalse"
            style="position:relative; height: 100%; min-height: 300px"
          ></div>
        

          <div class="history">
            <p>
              <strong>Distance:</strong>
              {{distance}}
            </p>
            <p>
              <strong>Time:</strong>
              {{duration}}
            </p>
          </div>
        </div>
      </div>

      <div class="foot">
        <div class="actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </div> -->


</template>



<script>


// import { ref, onMounted } from "vue";
// export default {
//   props: ["lat", "lng", "destination"],
//   setup(props, context) {
//     const oracalse = ref("");
//     const address = ref("");
//     const destination = ref("");
//     const lat = ref("");
//     const lng = ref("");
//     const lat1 = ref("");
//     const lng1 = ref("");
//     const distance = ref(0);
//     const duration = ref(0);

//     const PopupHide = event => {
//       const dialog = document.querySelector(".dialog");

//       if (!dialog.contains(event.target)) {
//         context.emit("PopupOk");
//       }
//     };
//     const PopupHides = e => {
//       const dialog = document.querySelector(".close_btn");

//       if (dialog.contains(e.target)) {
//         context.emit("PopupOk");
//       }
//     };

//     onMounted(() => {
//       var coords = new google.maps.LatLng(23.810332, 90.4125181);
//       const selectMode = document.getElementById("mode").value;

//       const map = new google.maps.Map(oracalse.value, {
//         center: coords,
//         zoom: 15,
//         mapTypeId: google.maps.MapTypeId.ROADMAP
//       });

//       const directionsService = new google.maps.DirectionsService();
//       const directionsRenderer = new google.maps.DirectionsRenderer();

//       var start_lat = props.lat.toString();
//       var start_lon = props.lng.toString();
//       var end_lat = props.destination[1].toString();
//       var end_lon = props.destination[0].toString();

//       var request = {
//         origin: new google.maps.LatLng(start_lat, start_lon),
//         destination: new google.maps.LatLng(end_lat, end_lon),
//         // optimizeWaypoints: true,
//         // avoidHighways: false,
//         // avoidTolls: false,
//         travelMode: google.maps.TravelMode[selectMode]
//       };

//       directionsService.route(request, (response, status) => {
//         const directionData = response.routes[0].legs[0];

//         if (status == "OK") {
//           directionsRenderer.setDirections(response);
//           directionsRenderer.setMap(map);
//           // showMarker(directionData);
//           distance.value = directionData.distance.text;
//           duration.value = directionData.duration.text;
//           console.log(distance.value);
//           console.log(directionData);
//         }
//       });

//       document.getElementById("mode").addEventListener("change", () => {
//         const selectedMode = document.getElementById("mode").value;

//         var request = {
//           origin: new google.maps.LatLng(start_lat, start_lon),
//           destination: new google.maps.LatLng(end_lat, end_lon),
//           // optimizeWaypoints: true,
//           // avoidHighways: false,
//           // avoidTolls: false,
//           travelMode: google.maps.TravelMode[selectedMode]
//         };

//         directionsService.route(request, (response, status) => {
//           // console.log(response);
//           // console.log(status);

//           const directionData = response.routes[0].legs[0];

//           if (status == "OK") {
//             directionsRenderer.setDirections(response);
//             directionsRenderer.setMap(map);
//             distance.value = directionData.distance.text;
//             duration.value = directionData.duration.text;
//             //  console.log( distance.value);
//             //  console.log(directionData);
//           }
//         });
//       });
//     });

//     return {
//       PopupHide,
//       PopupHides,
//       oracalse,
//       lat,
//       lng,
//       lat1,
//       lng1,
//       destination,
//       distance,
//       duration
//     };
//   },

//   mounted() {
//     //    const geocoder = new Geocoder(this, Locale.getDefault());
//     //         console.log( geocoder.getFromLocation(45.4215296, -75.6971931, 1));
//   }
// };








</script>





<style lang="scss" scoped>
.history {
  margin-top: 10px;
}

.modal_container {
  visibility: hidden;
  overflow: hidden;
}
.modal_container.show {
  display: grid;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.4);
  grid-template-areas:
    "top-start top top-end"
    "center-start center center-end"
    "bottom-start bottom-center bottom-end";
  // grid-template-rows: minmax(-webkit-min-content,auto) minmax(-webkit-min-content,auto) minmax(-webkit-min-content,auto);
  // grid-template-rows: minmax(min-content,auto) minmax(min-content,auto) minmax(min-content,auto);
  height: 100%;
  padding: 10px;
  overflow-x: hidden;
  visibility: visible;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
  .dialog {
    display: grid;
    grid-column: 2;
    grid-row: 2;
    align-self: center;
    justify-self: center;
    grid-template-columns: minmax(0, 100%);

    position: relative;
    box-sizing: border-box;
    max-width: 600px;
    width: 100%;
    padding: 20px;
    border: none;
    border-radius: 5px;
    background: #fff;
    color: #545454;
    font-family: inherit;
    font-size: 16px;
    min-width: 350px;

    &.popup_show {
      -webkit-animation: success_popup_show 0.3s;
      animation: success_popup_show 0.3s;
    }
    &.popup_hide {
      -webkit-animation: success_popup_hide 0.15s forwards;
      animation: success_popup_hide 0.15s forwards;
    }
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .icon.success {
        position: relative;
        box-sizing: content-box;
        justify-content: center;
        width: 80px;
        height: 80px;
        margin: 16px auto 40px;
        border: 4px solid transparent;
        border-radius: 50%;
        font-family: inherit;
        line-height: 80px;
        cursor: default;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        // border-color: #a5dc86;
        // color: #a5dc86;
        .circle_left {
          position: absolute;
          width: 3.75em;
          height: 7.5em;
          transform: rotate(45deg);
          border-radius: 50%;

          top: -0.4375em;
          left: -2.0635em;
          transform: rotate(-45deg);
          transform-origin: 3.75em 3.75em;
          border-radius: 7.5em 0 0 7.5em;
        }
        .line_tip {
          display: block;
          position: absolute;
          z-index: 2;
          height: 0.3125em;
          border-radius: 0.125em;
          background-color: #a5dc86;
          top: 2.875em;
          left: 0.8125em;
          width: 1.5625em;
          transform: rotate(45deg);
          -webkit-animation: success_line_tip 0.75s;
          animation: success_line_tip 0.75s;
        }
        .line_long {
          display: block;
          position: absolute;
          z-index: 2;
          height: 0.3125em;
          border-radius: 0.125em;
          background-color: #a5dc86;
          top: 2.375em;
          right: 0.5em;
          width: 2.9375em;
          transform: rotate(-45deg);
          -webkit-animation: success_line_long 0.75s;
          animation: success_line_long 0.75s;
        }
        .ring {
          position: absolute;
          z-index: 2;
          top: -0.25em;
          left: -0.25em;
          box-sizing: content-box;
          width: 100%;
          height: 100%;
          border: 0.25em solid rgba(165, 220, 134, 0.3);
          border-radius: 50%;
        }
        .fix {
          position: absolute;
          z-index: 1;
          top: 0.5em;
          left: 1.625em;
          width: 0.4375em;
          height: 5.625em;
          transform: rotate(-45deg);
        }
        .circle_right {
          position: absolute;
          width: 3.75em;
          height: 7.5em;
          transform: rotate(45deg);
          border-radius: 50%;

          top: -0.6875em;
          left: 1.875em;
          transform: rotate(-45deg);
          transform-origin: 0 3.75em;
          border-radius: 0 7.5em 7.5em 0;
          -webkit-animation: success_circular_line 4.25s ease-in;
          animation: success_circular_line 4.25s ease-in;
        }
      }
      .icon.errors {
        position: relative;
        box-sizing: content-box;
        justify-content: center;
        width: 80px;
        height: 80px;
        margin: 16px auto 40px;
        border: 4px solid transparent;
        border-radius: 50%;
        font-family: inherit;
        line-height: 80px;
        border-color: #facea8;
        color: #f8bb86;
        cursor: default;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        -webkit-animation: error_icon 0.5s;
        animation: error_icon 0.5s;
        .error {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 3.75em;
          -webkit-animation: error_i_mark 0.5s;
          animation: error_i_mark 0.5s;
        }
      }
      select {
        width: fit-content;
        border: 1px solid #ddd;
        padding: 4px 8px;
        border-radius: 4px;
      }
      .close_btn {
        z-index: 3;
        .btn {
          background: tomato;
          padding: 6px 12px;
          font-size: 12px;
          &:hover {
            box-shadow: 0px 15px 20px rgba(243, 164, 74, 0.4);
          }
        }
      }
    }
    .body {
      .content {
        .title {
          color: #666;
          font-size: 24px;
          text-align: center;
          &.success {
            color: #a5dc86;
          }
          &.error {
            color: #facea8;
          }
        }
        .text {
          color: #777;
          font-size: 17px;
          text-align: center;
        }
        .history {
          margin-top: 20px;
          display: flex;
          column-gap: 20px;
          p {
            font-size: 14px;
            color: #777;
            line-height: 26px;
            strong {
              font-weight: 600;
              color: #444;
            }
          }
        }
      }
    }
    .foot {
      .actions {
        display: flex;
        margin-top: 40px;
        align-items: center;
        justify-content: center;
        column-gap: 20px;
      }
    }
  }
}
// First Show Popup
@-webkit-keyframes success_popup_show {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes success_popup_show {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes success_popup_hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes success_popup_hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

// Circle
@-webkit-keyframes success_circular_line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes success_circular_line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

// Success Trip/Long
@-webkit-keyframes success_line_tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}
@keyframes success_line_tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}
@-webkit-keyframes success_line_long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes success_line_long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}

// Error
@-webkit-keyframes error_icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes error_icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes error_i_mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0;
  }
  25% {
    transform: rotateZ(-25deg);
    opacity: 0.4;
  }
  50% {
    transform: rotateZ(15deg);
    opacity: 0.8;
  }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes error_i_mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0;
  }
  25% {
    transform: rotateZ(-25deg);
    opacity: 0.4;
  }
  50% {
    transform: rotateZ(15deg);
    opacity: 0.8;
  }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}
</style>