// import router from '@/router';
const ServiceModules = () => import('./ServiceModules.vue');
const ServicePageCrate = () => import('./page/ServiceCreatePage.vue');
const AddFriend = () => import('./page/AddFriend.vue');
//user permission
const UserPermission = () => import('./page/EmployeePermission.vue');
const UserPermissionEdit = () => import('./page/EmployeePermissionedit.vue');
const ViewUserPermission = () => import('./page/ViewEmployeePermission.vue');

const ShopManagerProductList = () => import('./page/ShopManagerProductList.vue');

const AddCommunity = () => import('./page/AddCommunity.vue');

const ServicePage = () => import('./page/ServicePage.vue');
// const ShopPage = () => import('./page/shop/ShopPage.vue');
// const ShopPage = () => import('@/modules/new-shop/views/shop/SingleShop.vue');
const ShopPage1 = () => import('./page/shop/ShopPage1.vue');
const ShopEditPage = () => import('./page/ServiceProfileEditPage.vue');
const CheckoutPage = () => import('./page/shop/CheckoutPage.vue');
const CheckoutPage1 = () => import('./page/shop/CheckoutPage1.vue');
const allOrder = () => import('./page/order/AllOrder.vue');
const pendingOrder = () => import('./page/order/PendingOrder.vue');
const processOrder = () => import('./page/order/ProcessOrder.vue');
const completeOrder = () => import('./page/order/CompleteOrder.vue');
const cancleOrder = () => import('./page/order/CancleOrder.vue');
const Invoice = () => import('./page/order/InvoiceOrder.vue');
const InvoiceThank = () => import('./page/order/InvoiceOrderThank.vue');
const SellingInvoice = () => import('./page/order/SellingInvoiceOrder.vue');

//employee inventory section
const EmployeeInventory = () => import('./page/EmployeeInventory.vue');

import auth from '@/middleware/auth';

const moduleRoute = {
    path: "/user/service",
    component: ServiceModules,
  
    children:[
        {
            path: "/user/service",
            component: ServicePage,
        },

        {
            path: "/user/service/create",
            component: ServicePageCrate,
            meta:{
                middleware:[auth],
              }
        },
       
        {
            path: "/user/service/add_friend",
            component: AddFriend,
            meta:{
                middleware:[auth],
              }
        },

        
        {
            path: "/user/service/user_permission/:spid",
            component: UserPermission,
            meta:{
                middleware:[auth],
              }
        },

        
        {
            path: "/user/service/user_permission_edit/:spid/:id",
            component: UserPermissionEdit,
            meta:{
                middleware:[auth],
              }
        },

        {
            path: "/user/service/user_permission_view/:spid",
            component: ViewUserPermission,
            meta:{
                middleware:[auth],
              }
        },



        {
            path: "/user/service/add_community",
            component: AddCommunity,
            meta:{
                middleware:[auth],
              }
        },
       

        // {
        //     path: "/user/service/shop/:pid",
        //     component: ShopPage,
        // },
        {
            path: "/:slug/:product_slug",
            component: ShopPage1,
            meta:{
                product: 'details'
            }
        },
        {
            path: "/user/service/profile-edit/:pid",
            component: ShopEditPage,
        },

        {
            path:"/user/service/shop/checkout",
            component: CheckoutPage,
            meta:{
                middleware:[auth],
              }
        },
        
        {
            path:"/user/service/shop/checkout/:pid",
            component: CheckoutPage1,
            // meta:{
            //     middleware:[auth],
            //   }
        },



        {
            path:"/user/service/shop/all-order",
            component: allOrder,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/user/service/shop/pending-order",
            component: pendingOrder,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/user/service/shop/process-order",
            component: processOrder,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/user/service/shop/complete-order",
            component: completeOrder,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/user/service/shop/cancle-order",
            component: cancleOrder,
            meta:{
                middleware:[auth],
              }
        },
        {
            path:"/user/service/shop/all-invoice/:id",
            component: Invoice,
            // name: 'invoice-to',
            // meta:{
            //     middleware:[auth],
            //   }
        },

        {
            path:"/user/service/shop/selling-invoice/:spid/:id",
            component: SellingInvoice,
            // name: 'invoice-to',
            // meta:{
            //     middleware:[auth],
            //   }
        },


        {
            path:"/user/service/shop/thanksforshopping/:id",
            component: InvoiceThank,
            // name: 'invoice-to',
            // meta:{
            //     middleware:[auth],
            //   }
        },
        {
            path: "/user/service/employee-inventory",
            name: "employee-inventory",
            component: EmployeeInventory,
          },

        {
            path: "/:slug/manager-product-list/:uid",
            name: "shop-manager-product-list",
            component: ShopManagerProductList,
          },


        
    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  