import AppStorage from "./AppStorage";
import Token from "./Token";
import axios from 'axios';
import { useGtm } from "@gtm-support/vue-gtm";

let User = class User {

    login(data, router, store) {
        axios.post('/api/login', data)
            .then(res => {
                if (res.data.type != 'failure') {
                    this.responseAfterLogin(res)
                    router.go({ name: 'Home-page' });
                    localStorage.removeItem('mobile');
                    localStorage.removeItem('login_error_message');
                } else {
                    const payload = {
                        type: res.data.type,
                        message: res.data.data
                    };
                    store.dispatch('addNotification', payload);
                    localStorage.setItem('login_error_message', res.data.data);
                }

            })
            .catch(error => console.log(error))
    }

    register(data, router, store) {
        axios.post('/api/register', data)
            .then(res => {
                // this.responseAfterLogin(res)
                // router.go({ name: 'Home-page' });
                // localStorage.removeItem('mobile');
                if (res.data?.type != 'failure') {
                    this.responseAfterLoginTo(res)
                    router.go({ name: 'Home-page' });
                    localStorage.removeItem('mobile');
                    localStorage.removeItem('login_error_message');
                } else {
                    const payload = {
                        type: res.data?.type,
                        message: res.data?.data
                    };
                    store.dispatch('addNotification', payload);
                    localStorage.setItem('login_error_message', res.data?.data);
                }
            })
            .catch(error => console.log(error.response?.message))
    }

    responseAfterLogin(res) {
        // console.log(res);
        const access_token = res.data.access_token;
        const username = res.data.user.name;
        const reseller = res.data.user?.reseller;
        const address = res.data.user.address;
        const employee = res.data.user?.is_employee;
        const uid = res.data.user.uid;
        const mobile = res.data.user.mobile;
        const expaire = res.data.expires_in;
        const role = res.data.role?.role_name;
        const rolev = res.data.role?.role_value;
        const localhost = res.data.localhost?.lid;
        const ambasidor = res.data.communityambasidor?.caid;

        if (Token.isValid(access_token)) {
            AppStorage.store(username, access_token, mobile, expaire, role, rolev, uid, address, employee, localhost, ambasidor, reseller);
        }
    }

    responseAfterLoginTo(res) {
        const access_token = res.data.access_token;
        const username = res.data.user.name;
        const reseller = res.data.user?.reseller;
        const address = res.data.user.address;
        const employee = res.data.user?.is_employee;
        const uid = res.data.user.uid;
        const mobile = res.data.user.mobile;
        const expaire = res.data.expires_in;
        const role = res.data.role?.role_name;
        const rolev = res.data.role?.role_value;
        const localhost = res.data.localhost?.lid;
        const ambasidor = res.data.communityambasidor?.caid;

        const gtm = useGtm()

        if (gtm.enabled()) {

            fetch('https://api.db-ip.com/v2/free/self')

                .then(function (response) {
                    return response.json()
                })
                .then(function (data) {
                    window.dataLayer?.push({
                        event: "CompleteRegistration",
                        name: res.data.user.name,
                        phone: res.data.user.mobile,
                        address: res.data.user.address,
                        uid: res.data.user.uid,
                        ip_address: data.ipAddress
                    });
                });

        }

        if (Token.isValid(access_token)) {
            AppStorage.store(username, access_token, mobile, expaire, role, rolev, uid, address, employee, localhost, ambasidor, reseller);
        }

        
    }

    responseAfterLoginFast(res) {
        const access_token = res.access_token;
        const username = res.user.name;
        const reseller = res.user?.reseller;
        const address = res.user.address;
        const employee = res.user?.is_employee;
        const uid = res.user.uid;
        const mobile = res.user.mobile;
        const expaire = res.expires_in;
        const role = res.role?.role_name;
        const rolev = res.role?.role_value;
        const localhost = res.localhost?.lid;
        const ambasidor = res.communityambasidor?.caid;

        const gtm = useGtm()

        if (gtm.enabled()) {

            fetch('https://api.db-ip.com/v2/free/self')

                .then(function (response) {
                    return response.json()
                })
                .then(function (data) {
                    window.dataLayer?.push({
                        event: "CompleteRegistration",
                        name: res.user.name,
                        phone: res.user.mobile,
                        address: res.user.address,
                        uid: res.user.uid,
                        ip_address: data.ipAddress
                    });
                });

        }

        if (Token.isValid(access_token)) {
            AppStorage.store(username, access_token, mobile, expaire, role, rolev, uid, address, employee, localhost, ambasidor, reseller);
        }

    }


    hasToken() {
        const storedToken = AppStorage.getToken();
        if (storedToken) {
            return Token.isValid(storedToken) ? true : this.logOut();
        } else {
            this.logOut();

        }
        return false;
    }


    logedIn() {
        return this.hasToken();
    }

    logOut() {
        AppStorage.clear();
    }

    name() {
        if (this.logedIn()) {
            return AppStorage.getUser();
        }
    }
    phone() {
        if (this.logedIn()) {
            return AppStorage.getPhone();
        }
    }

    id() {
        if (this.logedIn()) {
            const payload = Token.payload(AppStorage.getToken());
            return payload.sub;
        }
    }

    own(id) {
        return this.id() == id;
    }
    uid() {
        return AppStorage.getUid();
    }

    admin() {
        return AppStorage.getAdmin() == 'admin';
    }
    getAdminType() {
        return AppStorage.getAdminType();
    }
    getSupperAdmin() {
        return AppStorage.getAdminType() == 'superadminstor'
    }
    getEmployee() {
        return AppStorage.getEmployee();
    }
    getLocalhost() {
        return AppStorage.getLocalhost();
    }

    getAmbasidor() {
        return AppStorage.getAmbasidor();
    }
    getReseller() {
        return AppStorage.getReseller();
    }

    getChatId() {
        return AppStorage.getChatId();
    }


}

export default User = new User();