// import router from '@/router';
import auth from '@/middleware/auth.js';
const UserModule = () => import('./UserModule.vue');
const HomePage = () => import('./view/home/HomePage.vue');
const BalanceWithdrawPage = () => import('./view/home/BalanceWithdrawPage.vue');

//employee section start
const WorkHistory = () => import('./view/work_history/WorkHistory.vue');
const UserTopup = () => import('./view/work_history/UserTopup.vue');

//Wallet start
const WalletHistory = () => import('./view/wallet/WalletHistory.vue');
const TransactionHistory = () => import('./view/wallet/TransactionHistory.vue');
const TodayTransaction = () => import('./view/wallet/TodayTransaction.vue');
const YesterdayTransaction = () => import('./view/wallet/YesterdayTransaction.vue');
const WeeklyTransaction = () => import('./view/wallet/WeeklyTransaction.vue');

//employee section start shop section
const AllShop = () => import('./view/work_history/shop/AllShop.vue');
const EditShop = () => import('./view/work_history/shop/EditShop.vue');
const IncompleteShop = () => import('./view/work_history/shop/IncompleteShop.vue');
const completeShop = () => import('./view/work_history/shop/CompleteShop.vue');
const PaidShop = () => import('./view/work_history/shop/PaidShop.vue');
const UnpaidShop = () => import('./view/work_history/shop/UnpaidShop.vue');
const CategoryCommission = () => import('./view/work_history/shop/CategoryCommission.vue');


// employee product add section

const ProductAdd = () => import('./view/work_history/product/ProductAdd.vue');
const ViewProduct = () => import('./view/work_history/product/ViewProduct.vue');
//selling purpose
const SellingPage = () => import('./view/selling/SellingOrder.vue');
const SellingManagerPage = () => import('./view/selling/SellingManagerPage.vue');
// const ZfCourier = () => import('./view/selling/ZfCourier.vue');


// employee report section
const EmployeeAddReport = () => import('./view/employee_report/EmployeeAddReport.vue');
const EmployeeViewReport = () => import('./view/employee_report/EmployeeViewReport.vue');
const EmployeeEditReport = () => import('./view/employee_report/EmployeeEditReport.vue');

//employee inventory section
const InventoryHistory = () => import('./view/work_history/inventory/InventoryHistory');
//user access section
const UserAccess = () => import('./view/work_history/user_access/UserAccess.vue');

// add section here
const AddAd = () => import('./view/home/adsection/AddAd.vue');
const ViewAdList = () => import('./view/home/adsection/ViewAdList.vue');
const ViewAdsListDetails = () => import('./view/home/adsection/ViewAdsListDetails.vue')

//employee section end
const moduleRoute = {
    path: "/mypanel",
    component: UserModule,
    meta:{
        middleware:[auth],
      },
  
    children:[


       {
        path: "/mypanel",
        component: HomePage,
        meta:{
            middleware:[auth],
          }
       },

       //user employee work history start

       {
        path: "/user/work-history",
        component: WorkHistory,
        meta:{
            middleware:[auth],
          }
       },

       // user employee work history shop start
       {
        path: "/user/work-history/all-shop",
        component: AllShop,
        meta:{
            middleware:[auth],
          }
       },

       {
        path: "/user/work-history/edit_shop/:pid",
        component: EditShop,
        meta:{
            middleware:[auth],
          }
       },

       {
        path: "/user/work-history/incomplete-shop",
        component: IncompleteShop,
        meta:{
            middleware:[auth],
          }
       },

       {
        path: "/user/work-history/complete-shop",
        component: completeShop,
        meta:{
            middleware:[auth],
          }
       },
       {
        path: "/user/work-history/paid-shop",
        component: PaidShop,
        meta:{
            middleware:[auth],
          }
       },


       {
        path: "/user/work-history/unpaid-shop",
        component: UnpaidShop,
        meta:{
            middleware:[auth],
          }
       },

       {
        path: "/user/work-history/category_commission",
        component: CategoryCommission,
        meta:{
            middleware:[auth],
          }
       },

       {
        path: "/user/work-history/add-product/:pid",
        component: ProductAdd,
        meta:{
            middleware:[auth],
          }
       },


       {
        path: "/user/work-history/inventory-added",
        component: InventoryHistory,
        meta:{
            middleware:[auth],
          }
       },

       {
        path: "/user/shop-inventory-access-list",
        component: UserAccess,
        meta:{
            middleware:[auth],
          }
       },



       {
        path: "/employee/product/product/service/:pid",
        component: ViewProduct,
        meta:{
            middleware:[auth],
          }
       },


        //user employee work history end

       {
        path: "/mypanel/withdraw",
        component: BalanceWithdrawPage,
        meta:{
            middleware:[auth],
          }
       },

       {
        path: "/user/selling-purpose-order",
        component: SellingPage,
        meta:{
            middleware:[auth],
          }
       },

       {
        path: "/user/manager-selling-purpose-order",
        component: SellingManagerPage,
        meta:{
            middleware:[auth],
          }
       },


       //employee report route section
       {
        path: "/user/employee-report",
        component: EmployeeAddReport,
        // meta:{
        //     middleware:[auth],
        //   }
       },

       
       {
        path: "/user/employee-report-view",
        component: EmployeeViewReport,
        meta:{
            middleware:[auth],
          }
       },

       {
        path: "/user/employee-report-edit/:id",
        component: EmployeeEditReport,
        meta:{
          middleware:[auth],
        }
       },


       {
        path: "/user/wallet-history",
        component: WalletHistory,
        meta:{
          middleware:[auth],
        }
       },
       {
        path: "/user/transaction-history",
        component: TransactionHistory,
        meta:{
          middleware:[auth],
        }
       },

       {
        path: "/user/today-transaction",
        component: TodayTransaction,
        meta:{
          middleware:[auth],
        }
       },

       {
        path: "/user/yesterday-transaction",
        component: YesterdayTransaction,
        meta:{
          middleware:[auth],
        }
       },
       {
        path: "/user/weekly-transaction",
        component: WeeklyTransaction,
        meta:{
          middleware:[auth],
        }
       },
       {
        path: "/user/top-up",
        component: UserTopup,
        meta:{
          middleware:[auth],
        }
       },

       {
        path: "/addsection-add-ads",
        component: AddAd,
        meta:{
          middleware:[auth],
        }
       },

       {
        path: "/addsection-view-ads-list",
        component: ViewAdList,
        meta:{
          middleware:[auth],
        }
       },

       {
        path: "/view-ads-section-view-ads-list/:id",
        component: ViewAdsListDetails,
        meta:{
          middleware:[auth],
        }
       },



    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  