import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const AdminRoleModules = () => import('./AdminRoleModules.vue');
const AdminRolePage = () => import('./page/AdminRolePage.vue');
const AdminRoleAddPage = () => import('./page/AdminRoleAddPage.vue');
const AdminRoleEditPage = () => import('./page/AdminRoleEditPage.vue');

const moduleRoute = {
    path: "/admin/role-section",
    component: AdminRoleModules,
    meta:{
        middleware:[auth, admin],
      },
  
    children:[
        {
            path: "/admin/role-section",
            component: AdminRolePage,
            meta:{
                middleware:[auth, admin],
              },
        },
        {
          path: "/admin/role-section/add-role",
          component: AdminRoleAddPage,
          meta:{
              middleware:[auth, admin],
            },
      },
      {
        path: "/admin/role-section/edit-role/:id",
        component: AdminRoleEditPage,
        meta:{
            middleware:[auth, admin],
          },
    },
    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  