import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const EmployeeManagementModules = () => import('./EmployeeManagementModules.vue');
// const EmployeePage = () => import('./page/EmployeePage.vue');

const EmployeePageRSM = () => import('./page/EmployeePageRSM.vue');
const EmployeePageAM = () => import('./page/EmployeePageAM.vue');
const EmployeePageSA = () => import('./page/EmployeePageSA.vue');
const EmployeePageRA = () => import('./page/EmployeePageRA.vue');

//rsm under
const EmployeePageRSMunderRa = () => import('./page/ra/RsmunderRa.vue');
const EmployeePageRSMunderAm = () => import('./page/am/RsmunderAm.vue');
const EmployeePageRSMunderSa = () => import('./page/sa/RsmunderSa.vue');

// am under
const EmployeePageAmunderRa = () => import('./page/ra/AmunderRa.vue');
const EmployeePageAmunderSa = () => import('./page/sa/AmunderSa.vue');

//sa under
const EmployeePageSaunderRa = () => import('./page/ra/SaunderRa.vue');

const moduleRoute = {
  path: "/admin/employee-management",
  component: EmployeeManagementModules,
  meta: {
    middleware: [auth, admin],
  },

  children: [
    {
      path: "/admin/employee-management",
      component: EmployeePageRSM,
      meta: {
        middleware: [auth, admin],
      },
    },

    
    // {
    //   path: "/admin/employee-management/am",
    //   component: EmployeePageAM,
    //   meta: {
    //     middleware: [auth, admin],
    //   },
    // },
    
    {
      path: "/admin/employee-management/rsm",
      component: EmployeePageRSM,
      meta: {
        middleware: [auth, admin],
      },
    },

    
    {
      path: "/admin/employee-management/rsm-under-ra/:uid",
      component: EmployeePageRSMunderRa,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/employee-management/rsm-under-sa/:uid",
      component: EmployeePageRSMunderSa,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/employee-management/rsm-under-am/:uid",
      component: EmployeePageRSMunderAm,
      meta: {
        middleware: [auth, admin],
      },
    },


    {
      path: "/admin/employee-management/am",
      component: EmployeePageAM,
      meta: {
        middleware: [auth, admin],
      },
    },


    {
      path: "/admin/employee-management/am-under-sa/:uid",
      component: EmployeePageAmunderSa,
      meta: {
        middleware: [auth, admin],
      },
    },


    {
      path: "/admin/employee-management/am-under-ra/:uid",
      component: EmployeePageAmunderRa,
      meta: {
        middleware: [auth, admin],
      },
    },


    {
      path: "/admin/employee-management/sa",
      component: EmployeePageSA,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/employee-management/sa-under-ra/:uid",
      component: EmployeePageSaunderRa,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/employee-management/ra",
      component: EmployeePageRA,
      meta: {
        middleware: [auth, admin],
      },
    },
   
  ]
};
export default router => {
  router.addRoute(moduleRoute);
  // console.log(router.getRoutes());
};
