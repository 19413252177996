require('./bootstrap');
import { createApp } from 'vue'
import App from './App.vue'
import { createGtm } from '@gtm-support/vue-gtm';
// import vueCountryRegionSelect from 'vue3-country-region-select'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import VueSocialSharing from 'vue-social-sharing'

const VueTelInputOptions = {
  mode: "national",
  // onlyCountries: ['BD', 'US'],
  defaultCountry: 'BD',
  autocomplete:'on',
}

import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import router from './router/index.js';
import store from './store/index.js'
//Fronted module
import cartModule from './modules/cart/index.js';
import productsModule from './modules/products/index.js';
import shopModule from './modules/shop/index.js';

//Admin module
import AdminModule from './modules/admin/index.js';
import AdminRoleModule from './modules/admin/view/admin_role/index.js';
import AdminServiceModule from './modules/admin/view/services/index.js';
import AdminDeliveryModule from './modules/admin/view/delivery/index.js';


import AdminEmployeeModule from './modules/admin/view/adminemployee/index.js';
import AdminEmployeeManagementModule from './modules/admin/view/adminemployeemanagement/index.js';
import AdminLocalhostModule from './modules/admin/view/localhost/index.js';
import AdminShopTextModule from './modules/admin/view/shop_text/index.js';


import AdminUserModule from './modules/admin/view/user/index.js';
import AdminWorkStationModule from './modules/admin/view/workstation/index.js';

import AdminCategoriesModule from './modules/admin/view/category/index.js';
import AdminCommunityCommissionModule from './modules/admin/view/community_section/index.js';

import AdminProductModule from './modules/admin/view/product/index.js';
import AdminFormModule from './modules/admin/view/form/index.js';
import AdminMultiFormModule from './modules/admin/view/multiform/index.js';
import AdminSliderModule from './modules/admin/view/slider/index.js';
import AdsSettingModules from './modules/admin/view/ads-setting/index.js';
import FairModules from './modules/admin/view/fair/index.js';



//User module
import UserModule from './modules/user/index.js';
import UserServiceModule from './modules/user/view/service/index.js';
import UserApplyModule from './modules/user/view/apply/index.js';
import UserGuestModule from './modules/guest/index.js';
import UserAdsModule from './modules/user/view/ads/index.js';
import UserLocalhostModule from './modules/user/view/localhost/index.js';
import UserAmbasidorModule from './modules/user/view/ambasidor/index.js';
// import UserProductModule from './modules/shop/views/product/index.js';
import UserNewShopModule from '@/modules/new-shop/index.js';
import UserPositionModule from '@/modules/user/view/position/index';

import InventorySection from '@/modules/user/view/inventory_section/index.js';
import InventoryRetailer from './modules/user/view/inventory_retailer/index.js';
import InventoryFarmacy from './modules/user/view/inventory_farmacy/index.js';
import InventoryMultipleProduct from './modules/user/view/inventory_mproduct/index.js';
import InventoryMenufecture from './modules/user/view/inventory_menufecture/index.js';


import { registerModules } from './register-modules';
registerModules({
  products: productsModule,
  cart: cartModule,
  shop: shopModule,


    // Admin
    admin: AdminModule,
    adminrole: AdminRoleModule,
    adminshoptext: AdminShopTextModule,
    admindelivery: AdminDeliveryModule,

    adminservice:AdminServiceModule,
    adminemployee:AdminEmployeeModule,
    adminemployeemanagement:AdminEmployeeManagementModule,
    adminelocalhost:AdminLocalhostModule,
    admincommunitycommissionmodule:AdminCommunityCommissionModule,
    adminuser:AdminUserModule,
    adminworkstation:AdminWorkStationModule,
    admincategories:AdminCategoriesModule,
    adminproduct:AdminProductModule,
    adminform:AdminFormModule,
    adminmultiform:AdminMultiFormModule,
    adminslider:AdminSliderModule,
    adminads:AdsSettingModules,
    adminfair:FairModules,

   // User
   user: UserModule,
   userservice: UserServiceModule,
   Userapply: UserApplyModule,
   userguest: UserGuestModule,
   userads: UserAdsModule,
   userlocalhost: UserLocalhostModule,
   userambasidor: UserAmbasidorModule,

   userposition: UserPositionModule,
   // Inventory section
   inventorysection: InventorySection,
   inventoryretailer: InventoryRetailer,
   inventoryfarmacy: InventoryFarmacy,
   inventorymproduct: InventoryMultipleProduct,
   inventorymenufecture: InventoryMenufecture,


   //  Dip
  newShop: UserNewShopModule,
});




const requireComponent = require.context(
    "./layout/base/",
    false,
    /^\.\/.*$/,
    // /base-[\w-]+\.vue$/,
    "sync"
  );
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;


import User from './middleware/User.js';
window.User = User;
const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(VueTelInput, VueTelInputOptions);

app.use(
  createGtm({
    id: 'GTM-MD4KHRRX', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    queryParams: {
      // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
      gtm_preview: 'env-4',
      gtm_cookies_win: 'x',
    },
    source: 'https://www.googletagmanager.com/gtm.js?id=GTM-MD4KHRRX', // Add your own serverside GTM script
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  }),
);



app.use(VueSocialSharing, {
  networks: {
    fakeblock: 'https://fakeblock.com/share?url=@url&title=@title'
  }
});

requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
  
    // const componentName = upperFirst(
    //   camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, ""))
    // );

    const componentName = upperFirst(
        camelCase(fileName.replace(/^\.\//, ""))
      );
    app.component(componentName, componentConfig.default || componentConfig);
  });


import Echo from "laravel-echo";
window.Pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: "231c11966fa5f2d6bb77",
  // wsHost:"razib.scommercebd.com",
  wsHost:"socket.scommercebd.com",
  wssHost:"socket.scommercebd.com",
  wsPort: "",
  wssPort: "",
  // wssPort: 6001,
  cluster: "eu",
  forceTLS: true,
  disableStats: true,
  // authEndpoint :'http://127.0.0.1:8000',
  // authEndpoint: 'https://razib.scommercebd.com/broadcasting/auth',
  auth:{
      headers: {
          Authorization: 'Bearer '+ localStorage.getItem('token'), 
      }
  },
});


// app.use(VueTelInput, options);
app.mount('#app');