import Axios from 'axios';
const RESOURCE_NAME = '/api/users/inventory';

export default{

  getSingleShopInfo(spid) {
        return Axios.get(`${RESOURCE_NAME}/view_won_shop_info/${spid}`);
      },

      getStockData(spid, id, page) {
        return Axios.get(`${RESOURCE_NAME}/view_inventory_stock/${spid}/${id}?page=${page}`);
      },

      getStockDataAll(spid, id) {
        return Axios.get(`${RESOURCE_NAME}/view_inventory_stock_all/${spid}/${id}`);
      },

      deleteInventory(id) {
        return Axios.delete(`${RESOURCE_NAME}/inventory_stock_delete/${id}`);
      },

      getItemData(spid, id, page) {
        return Axios.get(`${RESOURCE_NAME}/view_inventory_item/${spid}/${id}?page=${page}`);
      },

      getItemDataAll(spid, id) {
        return Axios.get(`${RESOURCE_NAME}/view_inventory_item_all/${spid}/${id}`);
      },


      getSingleResourceInfo(spid,id) {
        return Axios.get(`${RESOURCE_NAME}/view_inventory_stock_single_info/${spid}/${id}`);
      },


      getItemSingleInfoData(id) {
        return Axios.get(`${RESOURCE_NAME}/view_inventory_item_single_info/${id}`);
      },

      inventoryItemDelete(id) {
        return Axios.delete(`${RESOURCE_NAME}/inventory_item_delete/${id}`);
      },

      getItemSectionData(id){
        return Axios.get(`${RESOURCE_NAME}/get_item_data_process/`+id);
      },
      getItemSectionDataadd(id){
        return Axios.get(`${RESOURCE_NAME}/get_item_data_processadd/`+id);
      },

      getStockSectionData(id){
        return Axios.get(`${RESOURCE_NAME}/get_stock_data_process/${id}`);
      },


      insertItemIngradians(data){
        return Axios.post(`${RESOURCE_NAME}/insert_item_ingradians`, data);
      },

      getInventoryItemIngradianData(page, spid){
        return Axios.get(`${RESOURCE_NAME}/get_item_ingradians/${spid}?page=${page}`);
      },

      getInventoryItemIngradianDataAll(spid){
        return Axios.get(`${RESOURCE_NAME}/get_item_ingradians_all/${spid}`);
      },
      getItemGradianInfo(id){
        return Axios.get(`${RESOURCE_NAME}/get_item_ingradians_info/${id}`);
      },
      updateItemIngradians(data, id){
        return Axios.post(`${RESOURCE_NAME}/get_item_ingradians_info_update/${id}`, data);
      },
      deleteInventoryItemGradiants(id){
        return Axios.delete(`${RESOURCE_NAME}/item_ingradians_info_delete/${id}`);
      },
      getIngradianPriceDynamicChnage(id){
        return Axios.get(`${RESOURCE_NAME}/item_ingradians_info_price_data/${id}`);
      },
      getIngradianPriceDynamicChnageTotal(id, data){
        return Axios.get(`${RESOURCE_NAME}/item_ingradians_info_price_data_total/${id}?weight=`+data);
      },
      // menulist
      getAllMenuList(id){
        return Axios.get(`${RESOURCE_NAME}/get_total_menu_list/${id}`);
      },

      //confirm order 
      confirmOrder(data){
        return Axios.post(`${RESOURCE_NAME}/confirm_order_now`,data);
      },

      confirmOrderNowAll(data){
        return Axios.post(`${RESOURCE_NAME}/confirm_all_order_now`,data);
      },

      getOrderData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_data/${spid}/${uid}?page=${page}`);
      },
      
      getOrderDataAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_order_data_all/${spid}/${uid}`);
      },

      //inventory all sell report
      getAllSellData(spid, id, page){
        return Axios.get(`${RESOURCE_NAME}/view_all_sell_report/${spid}/${id}?page=${page}`);
      },

      getAllSellDataAllReport(spid, id){
        return Axios.get(`${RESOURCE_NAME}/view_all_sell_all_report/${spid}/${id}`);
      },

      //invoice get data
      getInvoiceOrderData(spid, id){
        return Axios.get(`${RESOURCE_NAME}/view_invoice_get_data/${spid}/${id}`);
      },

      //invoice get data
      updateDeliveryTime(data){
        return Axios.post(`${RESOURCE_NAME}/view_invoice_update_date_time`, data);
      },

      //order invoice updated
      orderInvoiceUpdate(data){
        return Axios.post(`${RESOURCE_NAME}/order_invoice_update`, data);
      },

      orderInvoiceUpdateAll(data){
        return Axios.post(`${RESOURCE_NAME}/order_invoice_update_all`, data);
      },

      //order complete now after discount
      orderCompleteNowAfterDiscount(data){
        return Axios.post(`${RESOURCE_NAME}/order_complete_now_after_discount`, data);
      },


      //Report Section

      getAllReportAll(id){
        return Axios.get(`${RESOURCE_NAME}/get_all_report_all_data/${id}`);
      },

      
      //get daily report here count
      getDaillyReportAll(id){
        return Axios.get(`${RESOURCE_NAME}/get_daily_report_data/${id}`);
      },


      getPaginateDailySellData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_daily_paginate_report_data/${spid}/${uid}?page=${page}`);
      },

      getAllDailySellDataAllReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_daily_all_report_data/${spid}/${uid}`);
      },

      //get weekly sell report
      getWeeklyReportAll(id){
        return Axios.get(`${RESOURCE_NAME}/get_weekly_report_data/${id}`);
      },

      getPaginateWeeklySellData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_weekly_paginate_report_data/${spid}/${uid}?page=${page}`);
      },

      getAllWeeklySellDataAllReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_weekly_all_report_data/${spid}/${uid}`);
      },

      //get Montly sell report
      getMonthlyReportAll(id){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_report_data/${id}`);
      },

      getPaginateMonthlySellData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_paginate_report_data/${spid}/${uid}?page=${page}`);
      },

      getAllMonthlySellDataAllReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_all_report_data/${spid}/${uid}`);
      },

      //get Montly sell report
      getLastMonthlyReportAll(id){
        return Axios.get(`${RESOURCE_NAME}/get_last_monthly_report_data/${id}`);
      },

      getPaginateLastMonthlySellData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_last_monthly_paginate_report_data/${spid}/${uid}?page=${page}`);
      },

      getAllLastMonthlySellDataAllReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_last_monthly_all_report_data/${spid}/${uid}`);
      },

      
      //get Last Thirty Days sell report
      getLastThirtyDaysReportAll(id){
        return Axios.get(`${RESOURCE_NAME}/get_last_thirty_days_report_data/${id}`);
      },

      getPaginateLastThirtyDaysSellReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_last_thirty_days_paginate_report_data/${spid}/${uid}?page=${page}`);
      },

      getAllLastThirtyDaysSellDataAllReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_last_thirty_days_all_report_data/${spid}/${uid}`);
      },

      //estimate coast
      getEstimateCost(spid, id){
        return Axios.get(`${RESOURCE_NAME}/get_order_estimate_coast/${spid}/${id}`);
      },
      
      getEstimateCoastData(spid, id){
        return Axios.get(`${RESOURCE_NAME}/get_order_estimate_coast_total/${spid}/${id}`);
      },

      getEstimateCoastDataTotal(spid, id){
        return Axios.get(`${RESOURCE_NAME}/get_order_estimate_coast_total_inga/${spid}/${id}`);
      },

      //daily sheet
      getTodayStockData(spid){
        return Axios.get(`${RESOURCE_NAME}/get_today_stock_data/${spid}`);
      },

      // daily order
      getDailyOrderData(spid){
        return Axios.get(`${RESOURCE_NAME}/get_daily_order_data/${spid}`);
      },

      // daily order
      getDailytotalIng(spid){
        return Axios.get(`${RESOURCE_NAME}/get_daily_order_ing_data/${spid}`);
      },

      getMonthlyStockData(spid){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_stock_data/${spid}`);
      },

      getMonthlyTotalSell(spid, tarikhe){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_total_sell_data/${spid}?tarikhe=${tarikhe}`);
      },

      getMonthlyTotalIngradian(spid, tarikhe){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_total_ingradian_data/${spid}?tarikhe=${tarikhe}`);
      },

      getMonthlyTotalt(spid){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_total_total_data/${spid}`);
      },

      getMonthlyTotalGT(spid){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_total_gt_data/${spid}`);
      },
      //wasted section
      getAllStockList(spid){
        return Axios.get(`${RESOURCE_NAME}/get_all_stock_list_data/${spid}`);
      },

      confirmWaste(data){
        return Axios.post(`${RESOURCE_NAME}/get_all_stock_list_waste_data`, data);
      },

      confirmWasteAll(data){
        return Axios.post(`${RESOURCE_NAME}/get_all_stock_list_waste_data_all`, data);
      },

      // waste section record
      getPaginateDailyWasteData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_paginate_daily_waste_data/${spid}/${uid}?page=${page}`);
      },
      getAllDailyWasteDataAllReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_all_daily_waste_data/${spid}/${uid}?page=${page}`);
      },

      
      // waste section record weekly
      getPaginateWeeklyWasteData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_paginate_weekly_waste_data/${spid}/${uid}?page=${page}`);
      },
      getAllWeeklyWasteDataAllReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_all_weekly_waste_data/${spid}/${uid}?page=${page}`);
      },

      
      // waste section record monthly
      getPaginateMonthlyWasteData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_paginate_monthly_waste_data/${spid}/${uid}?page=${page}`);
      },
      getAllMonthlyWasteDataAllReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_all_monthly_waste_data/${spid}/${uid}?page=${page}`);
      },
  

      
      // waste section record monthly
      getPaginateLastMonthlyWasteReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_paginate_monthly_last_waste_data/${spid}/${uid}?page=${page}`);
      },
      getAllMonthlyLastWasteDataAllReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_all_monthly_last_waste_data/${spid}/${uid}?page=${page}`);
      },

      // waste section record monthly
      getPaginateLastThirtyDaysWasteReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_paginate_last_thirty_days_waste_data/${spid}/${uid}?page=${page}`);
      },
      getAllLastThirtyWasteDataAllReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_all_last_thirty_days_waste_data/${spid}/${uid}?page=${page}`);
      },

      // waste section record all
      getPaginateAllWasteReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_paginate_all_waste_data/${spid}/${uid}?page=${page}`);
      },
      getAllWasteDataAllReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_all_all_waste_data/${spid}/${uid}?page=${page}`);
      },
      
      
      //utility coast section
      // getAllWasteDataAllReport(data){
      //   return Axios.post(`${RESOURCE_NAME}/add_utility_coast`, data);
      // },

      getPaginateAllUtilityCoastReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_paginate_utility_coast_data/${spid}/${uid}?page=${page}`);
      },
      getAllUtilityCoastDataAllReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_all_utility_coast_data/${spid}/${uid}?page=${page}`);
      },
      
      getSalaryAllUtilityCoastReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_all_salary_coast_data/${spid}/${uid}?page=${page}`);
      },
      
      getOthersCoasttReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_all_others_coast_data/${spid}/${uid}?page=${page}`);
      },
      
      utilitySingleCoastDelete(spid, id){
        return Axios.delete(`${RESOURCE_NAME}/delete_single_utility_coast_data/${spid}/${id}`);
      },
      getMontlhyOtherCoast(spid){
        return Axios.get(`${RESOURCE_NAME}/get_all_monthly_other_coast_data/${spid}}`);
      },


      getPaginateAllOtherIncomeReport(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_paginate_other_income_data/${spid}/${uid}?page=${page}`);
      },
      getAllOtherIncomeDataAllReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_all_other_income_data/${spid}/${uid}`);
      },

      incomeSingleCoastDelete(spid, id){
        return Axios.delete(`${RESOURCE_NAME}/delete_single_other_income_data/${spid}/${id}`);
      },

      //retailer inventory

         //order complete now after discount
         orderCompleteNowAfterDiscountre(data){
          return Axios.post(`${RESOURCE_NAME}/order_complete_now_after_discount_re`, data);
        },
  
        orderCompleteNowAfterDiscountrePurchase(data){
          return Axios.post(`${RESOURCE_NAME}/order_complete_now_after_discount_re_purchase`, data);
        },
  
      
      productPurchaseUpdate(data){
        return Axios.post(`${RESOURCE_NAME}/product_purchase_section_here`, data);
      },
      productPurchaseUpdateAll(data){
        return Axios.post(`${RESOURCE_NAME}/product_purchase_section_here_all`, data);
      },

      getPurchaseDetails(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/purchase_product_details_history/${spid}/${uid}?page=${page}`)
      },
      getPurchaseDetailsAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/purchase_product_details_history_all/${spid}/${uid}`)
      },

      // daily order retailer
      getDailyOrderDataRetailer(spid){
        return Axios.get(`${RESOURCE_NAME}/get_daily_order_data_retailer/${spid}`);
      },
      getMonthlyStockDataRetailer(spid){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_stock_data_retailer/${spid}`);
      },

      getMonthlyTotalPurchase(spid, tarikhe){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_total_purchase_data/${spid}?tarikhe=${tarikhe}`);
      },

      getMonthlyTotalPurchaseData(spid){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_total_purchase_data_data/${spid}`);
      },

      getItemAlertData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_alert_item_data/${spid}/${uid}?page=${page}`)
      },

      getItemAlertDataAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_alert_item_data_all/${spid}/${uid}`)
      },

      customerDetailsUpdate(data){
        return Axios.post(`${RESOURCE_NAME}/update_user_details`, data)
      },
      // due section
      getOrderDueData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_due_data/${spid}/${uid}?page=${page}`)
      },
      getOrderDueDataAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_order_due_data_all/${spid}/${uid}`)
      },

      getOrderTodayDueData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_today_due_data/${spid}/${uid}?page=${page}`)
      },
      getOrderTodayDueDataAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_order_today_due_data_all/${spid}/${uid}`)
      },

      
      getOrderWeeklyDueData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_weekly_due_data/${spid}/${uid}?page=${page}`)
      },
      getOrderWeeklyDueDataAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_order_weekly_due_data_all/${spid}/${uid}`)
      },

      getOrderMonthlyDueData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_monthly_due_data/${spid}/${uid}?page=${page}`);
      },
      getOrderMonthlyDueDataAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_order_monthly_due_data_all/${spid}/${uid}`);
      },
      
      getOrderLastMonthlyDueData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_last_monthly_due_data/${spid}/${uid}?page=${page}`);
      },
      getOrderLastMonthlyDueDataAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_order_last_monthly_due_data_all/${spid}/${uid}`);
      },

      // getOrderLastMonthlyDueData(spid, uid, page){
      //   return Axios.get(`${RESOURCE_NAME}/get_order_last_monthly_due_data/${spid}/${uid}?page=${page}`);
      // },
      // getOrderLastMonthlyDueDataAll(spid, uid){
      //   return Axios.get(`${RESOURCE_NAME}/get_order_last_monthly_due_data_all/${spid}/${uid}`);
      // },

      getOrderLastThirtyDueData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_last_thirty_due_data/${spid}/${uid}?page=${page}`);
      },
      getOrderLastThirtyDueDataAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_order_last_thirty_due_data_all/${spid}/${uid}`);
      },
      getOrderAllDueDataAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_order_all_due_data_all/${spid}/${uid}`);
      },
      getDueOrderDetailsHistory(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_order_all_due_data_details_history/${spid}/${uid}`);
      },

      remarkFormSubmit(data){
        return Axios.post(`${RESOURCE_NAME}/get_order_all_due_data_remark`, data);
      },
      confirmSubscriptionProductAddServiceData(data){
        return Axios.post(`${RESOURCE_NAME}/confirm_subscription_product_add_service_data`, data);
      },
      // add product service category
      getAddProductServiceCategory(spid){
        return Axios.get(`${RESOURCE_NAME}/get_add_product_service_category/${spid}`);
      },

      getDailyOrderGrosseProfite(spid){
        return Axios.get(`${RESOURCE_NAME}/get_daily_order_grosse_profite/${spid}`);
      },

      getDailyOrderNetProfite(spid){
        return Axios.get(`${RESOURCE_NAME}/get_daily_order_net_profite/${spid}`);
      },

      getDailyOtherCoast(spid){
        return Axios.get(`${RESOURCE_NAME}/get_daily_order_other_cost/${spid}`);
      },

      getMonthlyOrderGrosseProfite(spid){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_order_grosse_profite/${spid}`);
      },

      getMonthlyOrderNetProfite(spid){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_order_net_profite/${spid}`);
      },

      getOrderCustomerList(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_customer_list/${spid}/${uid}?page=${page}`);
      },
      getOrderCustomerListAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_order_customer_list_all/${spid}/${uid}`);
      },
      getProductSearchData(spid, data){
        return Axios.get(`${RESOURCE_NAME}/get_product_search_filter/${spid}?search=${data}`);
      },

      getMobileCheckNow(data){
        return Axios.get(`${RESOURCE_NAME}/getMobile_number_check/${data}`);
      },

      getOrderDataSellSection(data){
        return Axios.get(`${RESOURCE_NAME}/get_order_data_sell_section/${data}`);
      },
      getInventoryCategoryData(spid){
        return Axios.get(`${RESOURCE_NAME}/get_inventory_category_data/${spid}`);
      },
      getInventoryFormData(spid){
        return Axios.get(`${RESOURCE_NAME}/get_inventory_form_data/${spid}`);
      },

      confirmWasteRe(data){
        return Axios.post(`${RESOURCE_NAME}/get_all_product_list_waste_data`, data);
      },

      confirmWasteReAll(data){
        return Axios.post(`${RESOURCE_NAME}/get_all_product_list_waste_data_all`, data);
      },
      
      inventoryOrderCancel(data){
        return Axios.get(`${RESOURCE_NAME}/get_order_list_form_cancel/${data}`);
      },

      inventoryOrderRestore(data){
        return Axios.get(`${RESOURCE_NAME}/get_order_list_form_restore/${data}`);
      },

      getOrderCustomerListLegard(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_customer_list_legard/${spid}/${uid}?page=${page}`);
      },

      getOrderCustomerListLegardDue(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_customer_list_legard_due/${spid}/${uid}?page=${page}`);
      },
      
      // due legar payment system
      paymentConfirms(data){
        return Axios.post(`${RESOURCE_NAME}/get_order_legard_due_payment_confirm`, data);
      },
      
      getOrderSupplierListLegardDue(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_supplier_list_legard_due/${spid}/${uid}?page=${page}`);
      },
      
      paymentConfirmsupplier(data){
        return Axios.post(`${RESOURCE_NAME}/get_order_supplier_legard_due_payment_confirm`, data);
      },

      getOrderSupplierListLegard(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_order_supplier_list_legard/${spid}/${uid}?page=${page}`);
      },

      getPurchasesItemSupplierList(spid){
        return Axios.get(`${RESOURCE_NAME}/get_purchases_item_supplier_list/${spid}`);
      },

      supplierDetailsUpdate(data){
        return Axios.post(`${RESOURCE_NAME}/update_supplier_user_details`, data);
      },

      getOrderSupplierList(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_supplier_user_list/${spid}/${uid}?page=${page}`);
      },

      getOrderSupplierListAll(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_supplier_user_list_all/${spid}/${uid}`);
      },

      FileUploadTest(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_supplier_user_list_all_file_upload/${spid}/${uid}`);
      },

      // report purchase section

      getAllPurchaseReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_all_purchase_report/${spid}/${uid}`);
      },
      // all report purchase
      getAllPurchaseData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_all_purchase_report_all_paginate/${spid}/${uid}?page=${page}`);
      },
      
      getAllPurchaseDataAllReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_all_purchase_report_all_paginate_all/${spid}/${uid}`);
      },
      // daily purchase section
      getDailyPurchaseData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_daily_purchase_report_paginate/${spid}/${uid}?page=${page}`);
      },
      
      getAllPurchaseDataDailyReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_daily_purchase_report_paginate_all/${spid}/${uid}`);
      },
      
      // daily purchase section
      getWeeklyPurchaseData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_weekly_purchase_report_paginate/${spid}/${uid}?page=${page}`);
      },
      
      getAllPurchaseDataWeeklyReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_weekly_purchase_report_paginate_all/${spid}/${uid}`);
      },
      
      // monthly purchase section
      getMonthlyPurchaseData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_purchase_report_paginate/${spid}/${uid}?page=${page}`);
      },
      
      getAllPurchaseDataMonthlyReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_monthly_purchase_report_paginate_all/${spid}/${uid}`);
      },
      
      // Last monthly purchase section
      getLastMonthlyPurchaseData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_last_monthly_purchase_report_paginate/${spid}/${uid}?page=${page}`);
      },
      
      getAllPurchaseDataLastMonthlyReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_last_monthly_purchase_report_paginate_all/${spid}/${uid}`);
      },
      
      // Last monthly purchase section
      getLastThirtyDaysPurchaseData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/get_last_thirty_days_purchase_report_paginate/${spid}/${uid}?page=${page}`);
      },
      
      getAllPurchaseDataLastThirtyDaysReport(spid, uid){
        return Axios.get(`${RESOURCE_NAME}/get_last_thirty_days_purchase_report_paginate_all/${spid}/${uid}`);
      },

      //start return section
      productReturnUpdateAll(data){
        return Axios.post(`${RESOURCE_NAME}/product_return_update_all`, data);
      },
      
    
      
      getReturnOrderData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/product_return_order_details/${spid}/${uid}?page=${page}`);
      },
      
      
   
      
      getDailayReturnData(spid){
        return Axios.get(`${RESOURCE_NAME}/get_daily_product_order_details/${spid}`);
      },

      //start purchase return section
      purchaseProductReturnUpdateAll(data){
        return Axios.post(`${RESOURCE_NAME}/purchase_product_return_update_all`, data);
      },

      getPurchaseReturnOrderData(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/purchase_product_return_order_details/${spid}/${uid}?page=${page}`);
      },
      
      postInventoryData(data){
        return Axios.post(`${RESOURCE_NAME}/post_inventory_pdf_data`, data);
      },


      // custom data filter
      getCustomSellData(data){
        return Axios.post(`${RESOURCE_NAME}/get_custom_sell_data`, data);
      },
      
      getCustomPurchaseData(data){
        return Axios.post(`${RESOURCE_NAME}/get_custom_purchase_data`, data);
      },

      
      getCustomDueData(data){
        return Axios.post(`${RESOURCE_NAME}/get_custom_due_data`, data);
      },
      
      
      getAllStockListMenufacture(id){
        return Axios.get(`${RESOURCE_NAME}/get_puprchase_stock_list_menufacture/${id}`);
      },

      stockPurchaseUpdateAll(data){
        return Axios.post(`${RESOURCE_NAME}/stock_purchase_section_here_all`, data);
      },

      stockSupplierDetailsUpdate(data){
        return Axios.post(`${RESOURCE_NAME}/stock_purchase_update_supplier_user_details`, data);
      },


      getStockPurchaseDetails(spid, uid, page){
        return Axios.get(`${RESOURCE_NAME}/stock_purchase_product_details_history/${spid}/${uid}?page=${page}`)
      },


      getPurchaseStockDataSearchData(spid, data){
        return Axios.get(`${RESOURCE_NAME}/stock_purchase_product_details_search/${spid}?search=${data}`)
      },

      getUserAccessCheck(spid){
        return Axios.get(`${RESOURCE_NAME}/get_user_inventory_access/${spid}`);
      }

      






   
    }