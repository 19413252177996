import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const CommunitySectionModules = () => import('./CommunitySectionModules.vue');
const CommunityAmbasidorCommisionPage = () => import('./page/work_commission/CommunityAmbasidorCommisionPage.vue');
const CommunityAmbasidorCommusionAddPage = () => import('./page/work_commission/CommunityAmbasidorCommusionAddPage.vue');
const CommunityAmbasidorCommisionEditPage = () => import('./page/work_commission/CommunityAmbasidorCommisionEditPage.vue');
const CommunityAmbasidorCommisionShopPage = () => import('./page/workhistory/CommunityAmbasidorAllShop.vue');
const CommunityAmbasidorCommisionProductPage = () => import('./page/workhistory/CommunityAmbasidorAllProduct.vue');
const CommunityAmbasidorApplyListPage = () => import('./page/apply_list/CommunityAmbasidorApplyLlist.vue');
const CommunityAdd = () => import('./page/community/AddCommunity.vue');
const CommunityView = () => import('./page/community/ViewCommunity.vue');
const CommunityEdit = () => import('./page/community/EditCommunity.vue');

// apply list
const ResellerApplyLlist = () => import('./page/apply_list/ResellerApplyLlist.vue');

const moduleRoute = {
  path: "/admin/community-ambasidor-work-commission",
  component: CommunitySectionModules,
  meta: {
    middleware: [auth, admin],
  },

  children: [
    {
      path: "/admin/community-ambasidor-work-commission",
      component: CommunityAmbasidorCommisionPage,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/community-ambasidor-work-commission-add",
      component: CommunityAmbasidorCommusionAddPage,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/community-ambasidor-work-commission-edit/:id",
      component: CommunityAmbasidorCommisionEditPage,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/community-ambasidor-work-history-shop",
      component: CommunityAmbasidorCommisionShopPage,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/community-ambasidor-work-history-product",
      component: CommunityAmbasidorCommisionProductPage,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/community-ambasidor-apply-list",
      component: CommunityAmbasidorApplyListPage,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/community-add",
      component: CommunityAdd,
      meta: {
        middleware: [auth, admin],
      },
    },
    {
      path: "/admin/community-view",
      component: CommunityView,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/community-edit/:id",
      component: CommunityEdit,
      meta: {
        middleware: [auth, admin],
      },
    },

    {
      path: "/admin/apply-list/reseller-list",
      component: ResellerApplyLlist,
      meta: {
        middleware: [auth, admin],
      },
    },



  ]
};
export default router => {
  router.addRoute(moduleRoute);
  // console.log(router.getRoutes());
};
