// import router from '@/router';
import auth from '@/middleware/auth.js';
const AmbasidorModules = () => import('./AmbasidorModules.vue');
const HomePage = () => import('./page/HomePage.vue');
const Transictions = () => import('./page/transactions/Transictions.vue');
const Product = () => import('./page/product/ViewProduct.vue');
const serviceProfile = () => import('./page/serviceProfile/ServiceProfile.vue');



//employee section end
const moduleRoute = {
    path: "/ambasidor-mypanel",
    component: AmbasidorModules,
    meta:{
        middleware:[auth],
      },
  
    children:[
       {
        path: "/ambasidor-mypanel",
        component: HomePage,
        meta:{
            middleware:[auth],
          }
       },

       {
        path: "/ambasidor-mypanel/transactions",
        component: Transictions,
        meta:{
            middleware:[auth],
          }
       },
       {
        path: "/ambasidor-mypanel/product",
        component: Product,
        meta:{
            middleware:[auth],
          }
       },
       {
        path: "/ambasidor-mypanel/service-profile",
        component: serviceProfile,
        meta:{
            middleware:[auth],
          }
       },

       //user employee work history start

    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  