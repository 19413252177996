const NewShopModule = () => import('./NewShopModule.vue');
const NewPage = () => import('./views/NewPage.vue');
const AllShop = () => import('./views/shop/AllShop.vue');
const SingleShop = () => import('./views/shop/SingleShop.vue');
const SingleService = () => import('./views/shop/SingleService.vue');
const VariationProduct = () => import('./views/shop/VariationProduct.vue');
const AllSlider = () => import('./views/shop/AllSlider.vue');
const SliderList = () => import('./views/shop/slider/SliderList.vue');
const SliderAdd = () => import('./views/shop/slider/SliderAdd.vue');
const SliderEdit = () => import('./views/shop/slider/SliderEdit.vue');
const InventorySuccess = () => import('./views/shop/template/InventorySuccess.vue');
const TopupSuccess = () => import('./views/shop/template/TopupSuccess.vue');
const ProfileSubcription = () => import('./views/shop/template/ProfileSubcription.vue');
const Error = () => import('./views/shop/template/Error.vue');
const BankLoanConsultency = () => import('./views/shop/BankLoanConsultency.vue');
const InsurenceApply = () => import('./views/shop/InsurenceApply.vue');
//shop section
const ApplySection = () => import('./views/shop/comp/ApplySection.vue');
const ApplyListSection = () => import('./views/shop/comp/ApplyListSection.vue');




const moduleRoute = {
    path: "/new",
    component: NewShopModule,

    children:[
        {
            path: "/new",
            component: NewPage,
        },
        {
            path: "/all-shop",
            component: AllShop,
        },
        
      

        {
            path: "/new/user/service/shop/:pid/:vid",
            component: VariationProduct,
        },
        {
            path: "/all-slider",
            component: AllSlider,
        },
        {
            path: "/user/service/shop/:pid/slides",
            component: SliderList,
        },
        {
            path: "/user/service/shop/:pid/slides/add",
            component: SliderAdd,
        },
        {
            path: "/user/service/shop/:pid/slides/edit/:id",
            component: SliderEdit,
        },

        {
            path: "/inventory-success",
            component: InventorySuccess,
        },
        {
            path: "/topup-success",
            component: TopupSuccess,
        },
        {
            path: "/profile-subsciption",
            component: ProfileSubcription,
        },

        {
            path: "/error-template",
            component: Error,
        },

        {
            path: "/bank-loan-consultency/:pid",
            component: BankLoanConsultency,
        },

        {
            path: "/insurence-apply/:pid",
            component: InsurenceApply,
        },
        // shop employee section
        {
            path: "/user/service_shop_apply/:pid/:id",
            component: ApplySection,
        },
        {
            path: "/user/service_shop_apply_list/:pid/:id",
            component: ApplyListSection,
        },
        {
            path: "/:slug/shop",
            component: SingleShop,
        },

        {
            path: "/:slug/service",
            component: SingleService,
        },

    ]
}

export default router => {
    router.addRoute(moduleRoute);
}