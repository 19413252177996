<template>
  <div class="dropdown-wrapper" :class="mode">
    <label for="label">{{label}}</label>
    <div class="dropdown-popover">
      <input
        class="input_field"
        type="text"
        :value="searchQuery"
        @input="e => searchQuery = e.target.value"
        :placeholder="placeholder"
        @focus="focusInput"
        @blur="blurInput"
      />
      <span class="icon-image">
        <img
          class="icon-image2"
          :class="{'dropdown-icon':icon}"
          src="@/assets/webp/arrow_up.webp"
          alt
        />
      </span>

      <!-- <span class="icon" @click="iconClick" :class="{'dropdown':icon}">&#x1F53C;</span> -->
      <div class="options">
        <ul v-if="isVisible">
          <li
            @click="selectedItems(user);  $emit('input', user.uid);  "
            v-for="(user, index) in filterUser"
            :key="`user-${index}`"
          >{{user.name}} - {{user.mobile}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },

    label: {
      type: String,
      default: ""
    },

    mode: {
      type: String,
      default: ""
    },

    placeholder: {
      type: String,
      default: ""
    },

    default: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      searchQuery: "",
      selectedItem: null,
      isVisible: false,
      icon: false,

      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0].name
        : null
      // open: false,
    };
  },
  computed: {
    filterUser() {
      const query = this.searchQuery.toLowerCase();
      if (this.searchQuery == "") {
        return this.options;
      }
      return this.options.filter(user => {
        if(user.name != null && user.mobile != null){
          
          return user.name.toLowerCase().startsWith(query) || user.mobile.includes(query);
        }
        
      });
    }
  },
  mounted() {
    // fetch('https://jsonplaceholder.typicode.com/users')
    //   .then(response => response.json())
    //   .then(json => {
    //     console.log(json)
    //     this.userArray = json;

    //   })

    this.$emit("input", this.selected);
  },

  methods: {
    selectedItems(user) {
      this.selectedItem = user;
      this.searchQuery = user.name +' - '+ user.mobile;
      this.isVisible = false;
    },
    focusInput() {
      this.searchQuery = "";
      this.icon = true;
      this.isVisible = true;
    },
    // iconClick(){
    //   this.searchQuery = '';
    //   this.icon = !this.icon;
    //   this.isVisible = !this.isVisible;
    // },
    blurInput() {
      // this.searchQuery = '';
      this.icon = false;
      var _ = this;
      setTimeout(() => {
        _.isVisible = false;
      }, 150);
    }
  }
};
</script>

<style scoped lang="scss">
indexing {
  z-index: 1;
}
indexing2 {
  z-index: 2;
}
indexing3 {
  z-index: 3;
}

.icon-image {
  position: absolute;
  width: 12px;
  height: 12px;
  right: 6px;
  top: 18px;

  z-index: 1;
  img {
    width: 12px;
    height: 12px;
  }
  .icon-image2 {
    transform: rotate(0deg);
    transition: all 0.5s easy;
    &.dropdown-icon {
      transform: rotate(180deg);
    }
  }
}
.dropdown-wrapper {
  max-width: 450px;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 70px;

  .dropdown-popover {
    background-color: #fff;
    // z-index:1;
    box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45),
      5px 5px 9px rgba(94, 104, 121, 0.3),
      -5px -5px 9px rgba(255, 255, 255, 0.45),
      5px 5px 9px rgba(94, 104, 121, 0.3);
    position: absolute;
    top: 25px;
    //   border: 2px solid lightgray;
    //   top: 46px;
    left: 0;
    right: 0;
    background-color: #fff;
    width: 100%;
    input {
      width: 100%;
      height: 45px;
      border: 2px solid lightgray;
      font-size: 16px;
      padding-left: 8px;

      border: none;
      outline: none;
      &:focus {
        border: none;
        outline: none;
      }
      &.input_field {
        z-index: 2;
        position: relative;
        background: transparent;
      }
    }
    .options {
      width: 100%;
      position: absolute;
      top: 45px;
      z-index: 3;
      box-shadow: 0 4px 20px rgb(0 0 0 / 25%);
      ul {
        list-style: none;
        text-align: left;
        // padding-left: 8px;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        li {
          width: 100%;
          border-bottom: 1px solid lightgray;
          padding: 10px;
          background-color: #f1f1f1;
          cursor: pointer;
          font-size: 16px;
          &:hover {
            background: #70878a;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>