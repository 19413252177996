import auth from '@/middleware/auth.js';
import admin from '@/middleware/admin';
const SliderModules = () => import('./SliderModules.vue');
const SliderPage = () => import('./comps/SliderPage.vue');
const SliderAddPage = () => import('./comps/SliderAddPage.vue');
const SliderEditPage = () => import('./comps/SliderEditPage.vue');

const moduleRoute = {
    path: "/admin/slides",
    component: SliderModules,
    meta:{
        middleware:[auth, admin],
      },
  
    children:[
        {
            path: "/admin/slides",
            component: SliderPage,
            meta:{
                middleware:[auth, admin],
            },
        },
        {
            path: "/admin/slides/add",
            component: SliderAddPage,
            meta:{
                middleware:[auth, admin],
            },
        },
        {
            path: "/admin/slides/edit/:id",
            component: SliderEditPage,
            meta:{
                middleware:[auth, admin],
            },
        },
    ]
};
export default router => {
    router.addRoute(moduleRoute);
    // console.log(router.getRoutes());
  };
  